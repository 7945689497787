.list-item {
	padding: 10px;
	margin: 0px 10px;
	background-color: var(--primary-bg-color);
	color: var(--color-dark);
	border-radius: 15px;
	margin-bottom: 20px;
	border: 1px solid var(--border-color-light);
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0px 10px 20px -20px #00000090, 0px 0px 30px 9px rgb(131 131 131 / 0%);
	}

	&.double-clicked {
		.task-title {
			color: var(--primary-color) !important;
		}
	}

	.header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin-bottom: 10px;
		padding: 5px 7px 3px 2px;
		transition: all 0.3s ease-in-out;
		border-radius: 30px;
		border: 1px dashed transparent;

		&:hover {
			background-color: var(--layout-body-background-color);
		}

		&.cursor-default {
			cursor: default !important;
		}

		app-project-status-toggle {
			margin-left: 5px;

			&:hover {
				margin-left: 10px;
			}

			button {
				padding-right: 0px;
				padding-left: 0px;
			}
		}

		.context-menu-container {
			flex-grow: 1;
			display: flex;
			flex-direction: row-reverse;
		}

		.title {
			font-size: 15px;
			font-weight: bold;
			padding-bottom: 10px;
			margin: 15px 0px;
		}
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.task-title {
			border-bottom: 2px solid var(--color-light);
			color: var(--color-dark);
			margin-bottom: 20px;
			padding-bottom: 5px;
			font-weight: 500;
			transition: all 0.2s ease-in-out;

			&:hover {
				text-decoration: underline;
				color: var(--color-dark);
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.sub-tasks-indicator {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.25rem;
			font-size: 0.75rem;
		}

		.assignee {
			align-self: flex-start;
		}

		.date {
			align-self: flex-end;
			font-size: 13px;
		}
	}
}

// Task date and time
:host ::ng-deep app-cycle-display span {
	font-size: 0.75rem !important;
}
