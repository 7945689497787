.modal {
	color: var(--color-dark);
	font-family: 'Inter';

	.modal-dialog {
		.modal-content {
			background-color: var(--primary-bg-color);
			border: 1px solid var(--border-color-light);
			border-radius: 15px;

			.modal-header {
				border-color: var(--border-color-dark);

				.modal-title {
					margin: 5px auto;
					cursor: default;
				}

				.close {
					@extend .btn;
					@extend .btn-sm;

					border: none;
					padding: 5px;
					border-radius: 100%;
					transition: all 0.25s ease-in-out;

					&:hover {
						background-color: var(--border-color-light);
					}

					svg path {
						stroke: var(--color-dark);
					}
				}
			}

			.modal-footer {
				border-color: var(--border-color-dark);
			}
		}
	}
}
