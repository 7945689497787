@import './../../../app/modules/project/components/task/board-task-item/board-task-item.component.scss';

.cdk-drag-placeholder {
	opacity: 1;
	background-color: #e91e6312;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.list-item-placeholder {
	@extend .list-item;

	border: 2px dashed #e91e6326;
	min-height: 200px;
	cursor: move;
}

.cdk-drag-handle {
	cursor: grab;
}

.cdk-drag-preview {
	app-board-task-item {
		.cdk-drag-handle {
			border-color: var(--todo-item-focused-background) !important;
			background-color: var(--layout-body-background-color);
		}
	}

	&app-field-item {
		.field-item {
			background-color: #f9fafb;
		}
	}
}
