button.icon-button {
	padding: 5px;
	border-radius: 8px;
	border: none;
	background-color: var(--primary-bg-color);
	transition: all 0.1s ease;

	&:hover {
		background-color: var(--bg-primary-active-light-hover);

		svg {
			* {
				stroke: var(--color-primary-hover);
			}
		}
	}

	svg {
		* {
			stroke: var(--color-light);
			transition: all 0.2s ease-in-out;
		}
	}
}
