/* toastify */

.toastify:hover {
    transition: all 0.2s ease-in-out;
    opacity: 1 !important;
}

.toast-close {
    padding: 0px;
    margin-left: 15px;
}
