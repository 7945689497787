.bee-tel-input {
	display: flex;
	flex-direction: column;
	text-align: start;
	margin-bottom: 10px;

	label {
		font-size: 17px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.iti {
		width: 100%;

		.country-dropdown {
			background-color: var(--primary-bg-color);
			border: 1px solid var(--border-color-light-hover);

			.search-container {
				input {
					background-color: var(--primary-bg-color);
					border: none;
					border-bottom: 1px solid var(--border-color-light-hover);
					padding: 10px;
				}
			}

			ul {
				background-color: var(--primary-bg-color);

				li {
					color: var(--color-dark);

					&:hover {
						background-color: var(--border-color-light-hover);
					}
				}
			}

			.iti__divider {
				border-bottom: 1px solid var(--scroll-bar-thumb);
			}
		}
	}

	.bee-phone-input {
		background-color: var(--primary-bg-color);
		border: 1px solid var(--border-color-light-hover);
		color: var(--color-dark);
		padding: 15px 20px;
		border-radius: 10px;
		padding: 8px 12px;
		font-size: 14px;
		color: var(--color-dark);
		outline: none;
		width: 100%;
		transition: all 0.3s ease-in-out;

		&::selection {
			background-color: var(--color-primary-hover);
			color: var(--color-dark);
		}

		&::placeholder {
			color: var(--color-placeholder);
		}

		&:focus {
			border-color: var(--color-primary-hover);
		}
	}

	&.lg {
		input {
			padding: 15px 20px;
			font-size: 16px;
		}
	}

	&.md {
		input {
			padding: 10px 15px;
			font-size: 15px;
		}
	}

	&.sm {
		input {
			padding: 7px 12px;
			font-size: 14px;
		}
	}
}

.ng-submitted {
	.ng-invalid {
		border: 1px solid var(--warning-color) !important;
		border-radius: 5px;
	}
}
