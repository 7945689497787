/**
  * Layout Flow
  *
  * Source: https://reactflow.dev/docs/examples/layout/dagre/
  */
.layoutflow {
	flex-grow: 1;
	position: relative;
	height: 100%;
}

.layoutflow .controls {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;
	font-size: 12px;
}

.layoutflow .controls button:first-child {
	margin-right: 10px;
}

.react-flow__handle {
	top: 24px;
	width: 6px;
	height: 10px;
	border-radius: 2px;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
}

.react-flow__node {
	> div {
		border-radius: 5px;
		box-shadow: none !important;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		> div {
			box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15) !important;
		}
	}
}
