.tui-colorpicker-container {
	.tui-colorpicker-palette-hex {
		width: 100%;
		height: 32px;
	}

	.tui-colorpicker-palette-preview {
		margin-top: 7px;
	}

	.toastui-editor-popup-color {
		button {
			font-size: 11px;
		}
	}
}
