.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: var(--color-primary-hover);
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: none !important;
}

.ant-select,
.ant-select-selector {
	border-radius: 5px !important;

	&:hover {
		border-color: var(--color-primary-light) !important;
	}
}
