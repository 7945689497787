.auth-layout {
	padding: 30px;
	margin: 0px;
	height: 100%;
	background-color: var(--auth-bg-color);
	color: var(--color-dark);

	.page-poster {
		position: relative;
		background-color: var(--auth-poster-bg-color);
		border-radius: 15px;
		height: 100%;
		width: 100%;

		.logo {
			left: 40px;
			top: 30px;
			position: absolute;
			width: auto;
			height: 60px;
		}

		.poster {
			/* height: 100%; */
			width: 90%;
			margin: 0px 50px;
		}
	}

	.auth-form-container {
		padding: 30px;
		margin-top: 50px;

		.auth-header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-bottom: 20px;

			h1 {
				margin-bottom: 25px;
			}

			p {
				font-size: 1.2rem;
				margin-bottom: 0px;
			}

			a {
				font-weight: bold;
			}
		}

		.form-control-container {
			margin-bottom: 25px;

			svg {
				* {
					stroke: var(--color-dark);
				}
			}
		}

		.fancy-checkbox-radio {
			label {
				color: #6b7280;
				font-size: 13px;
			}
		}
	}

	app-login-illustration {
		border-radius: 15px;

		svg {
			height: 100%;
			width: 100%;
		}
	}

	a {
		color: var(--primary-color) !important;
		text-decoration: none;
		transition: all 0.3s ease-in-out;

		&:hover {
			color: var(--color-primary-hover) !important;
		}
	}
}
