@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	// primary color palette
	--primary-25: #f7f5f2;
	--primary-50: #e7e0d7;
	// --primary-100: #d7cbbc;
	--primary-100: #e7e0d7;
	--primary-200: #c7b7a2;
	--primary-300: #b8a287;
	--primary-400: #a88e6d;
	--primary-500: #927857;
	--primary-600: #786247;
	--primary-700: #5d4d38;
	--primary-800: #433728;
	--primary-900: #282118;

	// secondary color palette
	--gray-25: #fcfcfd;
	--gray-50: #f9fafb;
	--gray-100: #f2f4f7;
	--gray-200: #eaecf0;
	--gray-300: #d0d5dd;
	--gray-400: #98a2b3;
	--gray-500: #667085;
	--gray-600: #475467;
	--gray-700: #344054;
	--gray-800: #1d2939;
	--gray-900: #101828;

	// red color palette
	--red-25: #fffbfa;
	--red-50: #fef3f2;
	--red-100: #fee4e2;
	--red-200: #fecdca;
	--red-300: #fda29b;
	--red-400: #f97066;
	--red-500: #f04438;
	--red-600: #d92d20;
	--red-700: #842318;
	--red-800: #912018;
	--red-900: #7a271a;

	// yellow color palette
	--yellow-25: #fffcf5;
	--yellow-50: #fffaeb;
	--yellow-100: #fef0c7;
	--yellow-200: #fedf89;
	--yellow-300: #fec84b;
	--yellow-400: #fdb022;
	--yellow-500: #f79009;
	--yellow-600: #dc6803;
	--yellow-700: #b54708;
	--yellow-800: #93370d;
	--yellow-900: #7a2e0e;

	// green color palette
	--green-25: #f6fef9;
	--green-50: #ecfdf3;
	--green-100: #d1fadf;
	--green-200: #a6f4c5;
	--green-300: #6ce9a6;
	--green-400: #32d583;
	--green-500: #12b76a;
	--green-600: #039855;
	--green-700: #027a48;
	--green-800: #05603a;
	--green-900: #054f31;

	// legacy color palette
	--primary-bg-color: #ffffff;
	--color-dark: #324054;
	--color-light: #71839b;
	--color-placeholder: #808080;
	--color-primary-active: #927857;
	--color-primary-light: #e7e0d7;
	--color-primary-hover: #786247;
	--color-primary-light-bg: #fff0f3;
	--bg-primary-active-light: #927857;
	--bg-primary-active-light-hover: #d7cbbc;
	--border-color-dark: #dcdcdc;
	--border-color-deep: #a3a3a3;
	--border-color-light: #e8e8e8;
	--border-color-light-hover: #d2d2d2;
	--primary-color: #927857;
	--primary-color-light: #e7e0d7;
	--secondary-color: #f4a047;
	--secondary-color-light: #fbcd9b;
	--success-color: #12b76a;
	--danger-color: #f04438;
	--danger-color-hover: #b42318;
	--warning-color: #f79009;
	--layout-body-background-color: #f8f9fc;
	--scroll-bar-thumb: #dbdbdb;
	--input-backgorund: #f5f5f58a;
	--input-backgorund-active: #f5f5f5b8;

	--todo-item-background: #f9fafb;
	--todo-item-focused-background: #d7cbbc;
	--todo-item-background-hover: #f2f2f2;

	--section-bg-color: #ffffff;
	--general-count-bubble-bg-color: #f5f5f5;

	--auth-bg-color: #fffefc;
	--auth-poster-bg-color: #fff0f3;
	--auth-input-bg-color: #ffffff;

	--primary-button-color: #ffffff;
	--primary-selection-color: #ffffff;
	--secondary-button-bg-color: #f0f8ff;
	--secondary-button-border-color: #ede4e8;
	--secondary-button-color: #927857;
	--table-tr-hover-bg-color: #f9f9f9;

	--card-border-radius: 10px;

	--attachment-download-button-text-color: #927857;
	--attachment-download-button-text-hover-color: #ffffff;
	--attachment-border-radius: 10px;
	--person-attachment-bg-color: #eff8ff;
	--person-attachment-bg-hover-color: #eef7ff;

	/* source: https://egoistdeveloper.github.io/npm-explorer/?p=tailwindcss-colors-css-variables@1.1.2-rc.2/css/tailwindcss-colors.css&selection=1:0-95:1 */
	--tw-color-transparent: transparent;
	--tw-color-black: #000;
	--tw-color-white: #fff;
	--tw-color-gray-50: #f9fafb;
	--tw-color-gray-100: #f7fafc;
	--tw-color-gray-200: #edf2f7;
	--tw-color-gray-300: #e2e8f0;
	--tw-color-gray-400: #cbd5e0;
	--tw-color-gray-500: #a0aec0;
	--tw-color-gray-600: #718096;
	--tw-color-gray-700: #4a5568;
	--tw-color-gray-800: #2d3748;
	--tw-color-gray-900: #1a202c;
	--tw-color-red-100: #fff5f5;
	--tw-color-red-200: #fed7d7;
	--tw-color-red-300: #feb2b2;
	--tw-color-red-400: #fc8181;
	--tw-color-red-500: #f56565;
	--tw-color-red-600: #e53e3e;
	--tw-color-red-700: #c53030;
	--tw-color-red-800: #9b2c2c;
	--tw-color-red-900: #742a2a;
	--tw-color-orange-100: #fffaf0;
	--tw-color-orange-200: #feebc8;
	--tw-color-orange-300: #fbd38d;
	--tw-color-orange-400: #f6ad55;
	--tw-color-orange-500: #ed8936;
	--tw-color-orange-600: #dd6b20;
	--tw-color-orange-700: #c05621;
	--tw-color-orange-800: #9c4221;
	--tw-color-orange-900: #7b341e;
	--tw-color-yellow-100: #fffff0;
	--tw-color-yellow-200: #fefcbf;
	--tw-color-yellow-300: #faf089;
	--tw-color-yellow-400: #f6e05e;
	--tw-color-yellow-500: #ecc94b;
	--tw-color-yellow-600: #d69e2e;
	--tw-color-yellow-700: #b7791f;
	--tw-color-yellow-800: #975a16;
	--tw-color-yellow-900: #744210;
	--tw-color-green-100: #f0fff4;
	--tw-color-green-200: #c6f6d5;
	--tw-color-green-300: #9ae6b4;
	--tw-color-green-400: #68d391;
	--tw-color-green-500: #48bb78;
	--tw-color-green-600: #38a169;
	--tw-color-green-700: #2f855a;
	--tw-color-green-800: #276749;
	--tw-color-green-900: #22543d;
	--tw-color-teal-100: #e6fffa;
	--tw-color-teal-200: #b2f5ea;
	--tw-color-teal-300: #81e6d9;
	--tw-color-teal-400: #4fd1c5;
	--tw-color-teal-500: #38b2ac;
	--tw-color-teal-600: #319795;
	--tw-color-teal-700: #2c7a7b;
	--tw-color-teal-800: #285e61;
	--tw-color-teal-900: #234e52;
	--tw-color-blue-100: #ebf8ff;
	--tw-color-blue-200: #bee3f8;
	--tw-color-blue-300: #90cdf4;
	--tw-color-blue-400: #63b3ed;
	--tw-color-blue-500: #4299e1;
	--tw-color-blue-600: #3182ce;
	--tw-color-blue-700: #2b6cb0;
	--tw-color-blue-800: #2c5282;
	--tw-color-blue-900: #2a4365;
	--tw-color-indigo-100: #ebf4ff;
	--tw-color-indigo-200: #c3dafe;
	--tw-color-indigo-300: #a3bffa;
	--tw-color-indigo-400: #7f9cf5;
	--tw-color-indigo-500: #667eea;
	--tw-color-indigo-600: #5a67d8;
	--tw-color-indigo-700: #4c51bf;
	--tw-color-indigo-800: #434190;
	--tw-color-indigo-900: #3c366b;
	--tw-color-purple-100: #faf5ff;
	--tw-color-purple-200: #e9d8fd;
	--tw-color-purple-300: #d6bcfa;
	--tw-color-purple-400: #b794f4;
	--tw-color-purple-500: #9f7aea;
	--tw-color-purple-600: #805ad5;
	--tw-color-purple-700: #6b46c1;
	--tw-color-purple-800: #553c9a;
	--tw-color-purple-900: #44337a;
	--tw-color-pink-100: #fff5f7;
	--tw-color-pink-200: #fed7e2;
	--tw-color-pink-300: #fbb6ce;
	--tw-color-pink-400: #f687b3;
	--tw-color-pink-500: #ed64a6;
	--tw-color-pink-600: #d53f8c;
	--tw-color-pink-700: #b83280;
	--tw-color-pink-800: #97266d;
	--tw-color-pink-900: #702459;

	--offcanvas-width: 300px;
	--topNavbarHeight: 56px;

	// Custom color palette for workspaces
	--orange-50: #fff7ed;
	--orange-500: #f97316;

	--pink-50: #fdf2f8;
	--pink-500: #ec4899;

	--blue-50: #eff6ff;
	--blue-500: #3b82f6;

	--indigo-50: #f5f3ff;
	--indigo-500: #6366f1;
}
