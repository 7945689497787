@media (prefers-color-scheme: dark) {
	html[data-theme='dark'] {
		--primary-bg-color: #292828;
		--color-dark: #c4c4c4;
		--color-light: #71839b;
		--color-placeholder: #808080;
		--color-primary-active: #e91e63;
		--color-primary-hover: #be5175;
		--bg-primary-active-light: #b82c5b69;
		--bg-primary-active-light-hover: #ed9eb91a;
		--border-color-dark: #4e4545;
		--border-color-deep: #a3a3a3;
		--border-color-light: #404040;
		--border-color-light-hover: #5f5f5f;
		--primary-color: #e91e63;
		--primary-color-light: #b95476;
		--success-color: #10b981;
		--danger-color: #ff3c30;
		--danger-color-hover: #ea4b62;
		--warning-color: #ffcc00;
		--layout-body-background-color: #0e0e0e;
		--scroll-bar-thumb: #474747;
		--input-backgorund: #434343;
		--input-backgorund-active: #464646;

		--todo-item-background: #2e3032;
		--todo-item-focused-background: #c6a6c3;
		--todo-item-background-hover: #3f3f3f;

		--section-bg-color: #33383c;
		--general-count-bubble-bg-color: #494545;

		--auth-bg-color: #384151;
		--auth-poster-bg-color: #4c5563;
		--auth-input-bg-color: #1f2937;

		--primary-button-color: #fff0f3;
		--primary-selection-color: #fff0f3;
		--table-tr-hover-bg-color: #393737;

		--card-border-radius: 10px;

		--attachment-download-button-text-color: #ffffff;
		--attachment-download-button-text-hover-color: #ffffff;
		--attachment-border-radius: 10px;
		--person-attachment-bg-color: #303a41;
		--person-attachment-bg-hover-color: #39434a;

		.daily-stats {
			color: var(--color-dark);

			.tasks,
			.performance-list {
				background-color: #323232 !important;
				border: 1px solid #484545 !important;

				.count,
				.current-rank,
				.name {
					color: var(--color-light) !important;
				}
			}
		}

		.form-control {
			&:focus {
				background-color: var(--input-backgorund-active);
				color: var(--color-dark);
			}

			&::selection {
				background-color: var(--primary-color-light);
				color: var(--color-dark);
			}
		}

		.pb-back {
			filter: brightness(0) invert(1);
		}
	}
}
