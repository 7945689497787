.badge {
    font-size: 13px;

    &.badge-pill {
        border-radius: 1.5rem;
        padding: 10px 20px !important;
    }

    &.badge-light {
        padding: 10px;
        color: black;

        &.primary {
            background-color: #fce7f3;
            color: #db2777;
        }

        &.success {
            background-color: #d1fae5;
            color: #059669;
        }

        &.danger {
            background-color: #fee2e2;
            color: #dc2626;
        }

        &.warning {
            background-color: #ffedd5;
            color: #f97316;
        }

        &.info {
            background-color: #ede9fe;
            color: #7c3aed;
        }
    }
}
