.home-container {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-top: 65px;

	.sidebar-container,
	.sidebar-nav {
		width: auto;
		min-width: 60px;
	}

	.main-body {
		flex-grow: 1;
	}
}

@include media-breakpoint-up(lg) {
	.home-container {
		.sidebar-container,
		.sidebar-nav {
			min-width: 270px;
		}
	}
}

.full-width-container {
	width: 100%;
	display: flex;
	flex-direction: row;

	.sidebar-container {
		width: 260px;
		height: 100vh;
		z-index: 1024;
		transition: all 0.15s ease-in-out;

		&.collapsed {
			width: 80px;
			transition: all 0.15s ease-in-out;

			.sidebar-nav {
				width: 80px;
				transition: all 0.15s ease-in-out;
			}
		}

		.sidebar-nav {
			top: 0px;
			margin-top: 0px;
			z-index: 10001;
			width: 260px;
			transition: all 0.15s ease-in-out;
		}
	}

	.main-body-container {
		margin-left: 65px;
		margin-top: 70px;
		width: 100%;
		margin-right: 25px;
		transition: all 0.15s ease-in-out;

		&.collapsed {
			margin-left: 30px;
			transition: all 0.15s ease-in-out;
		}
	}
}

@media (max-width: 1400px) {
	.full-width-container {
		.main-body-container {
			margin-left: 85px;
		}
	}
}
