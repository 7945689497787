// @source: https://codepen.io/codepoet/pen/ZERZvMj

.fancy-checkbox-radio {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.fancy-checkbox-radio label {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.fancy-checkbox-radio input[type='checkbox'],
.fancy-checkbox-radio input[type='radio'] {
	appearance: none;
	height: 20px;
	width: 20px;
	background: var(--primary-bg-color);
	border: 2px solid var(--border-color-light);
	margin: 0 8px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline-offset: 5px;
	outline-color: var(--border-color-light);
}

.fancy-checkbox-radio input[type='checkbox']:disabled,
.fancy-checkbox-radio input[type='radio']:disabled {
	opacity: 0.7;
	background: var(--border-color-light);
	cursor: not-allowed;
}

.fancy-checkbox-radio input[type='checkbox']:after,
.fancy-checkbox-radio input[type='radio']:after {
	content: '';
	display: block;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
}

.fancy-checkbox-radio input[type='checkbox']:checked:after,
.fancy-checkbox-radio input[type='radio']:checked:after {
	transform: scale(1);
}

.fancy-checkbox-radio input[type='checkbox'] {
	border-radius: 5px;
}

.fancy-checkbox-radio input[type='checkbox'].checkbox-md {
	height: 22px;
	width: 22px;
	margin-top: 5px;
}

.fancy-checkbox-radio input[type='checkbox']:after {
	width: 16px;
	height: 16px;
}

.fancy-checkbox-radio input[type='checkbox']:checked {
	border: none;
	background-color: var(--primary-color);
}

.fancy-checkbox-radio input[type='checkbox']:checked:after {
	content: url("data:image/svg+xml, <svg viewBox='-2 -2 20 25' xmlns='http:\/\/www.w3.org\/2000\/svg'><path fill='white' d='M15.25.847a1.51 1.51 0 0 1 .405 2.096L8.106 14.11a1.944 1.944 0 0 1-2.94.329L.6 10.156a1.51 1.51 0 1 1 2.067-2.202l3.645 3.42 6.841-10.122a1.51 1.51 0 0 1 2.098-.405Z'\/><\/svg>");
}

.fancy-checkbox-radio input[type='radio'] {
	border-radius: 20px;
}

.fancy-checkbox-radio input[type='radio']:after {
	width: 14px;
	height: 14px;
	border-radius: 7px;
}

.fancy-checkbox-radio input[type='radio']:checked {
	border-color: var(--primary-color);
}

.fancy-checkbox-radio input[type='radio']:checked:after {
	background-color: var(--primary-color);
}

.fancy-checkbox-radio a {
	font-weight: bold;
}
