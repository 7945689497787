#lightbox-lightbox {
	margin: auto;
	padding: 100px 50px 50px 50px;
	background-color: #00000060;
	backdrop-filter: blur(2px);
	margin: auto;
	box-shadow: none;

	gallery {
		background-color: transparent;

		.g-thumbs-container {
			margin-top: 15px;
			height: 75px;
		}
	}

	.g-image-item {
		background-size: auto;
		background-size: contain !important;
	}
}
