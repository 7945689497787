.ant-modal {
	.ant-modal-header {
		.ant-modal-title {
			font-weight: bold;
		}
	}

	.ant-modal-content {
		border-radius: 10px;

		.ant-modal-header {
			border-radius: 10px 10px 0 0;
		}
	}
}

.ant-modal-mask {
	backdrop-filter: blur(2px);
	background-color: rgba(0, 0, 0, 0.1);
}
