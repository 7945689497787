body {
	background-color: var(--layout-body-background-color);
}

b {
	font-weight: 500;
}

.bg-lipstick {
	background-color: var(--primary-bg-color);
}

.logo-bx {
	margin: 0 45%;
}

.logo-bx-mr {
	margin: 0 37%;
}

.search-input {
	/* background: url(../assets/img/searchbox@2x.png); */
	/* width: auto; */
	height: 60%;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	border: none;
	border: 1px solid var(--border-color-dark);
	background-color: var(--primary-bg-color);
}

.search-btn {
	background: url(../assets/img/search.svg);
	height: 21px;
	width: 21px;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: contain;
	padding-right: 15px;
}

.search-magenta {
	background: url(../assets/img/magenta-search.svg) no-repeat;
	height: 25px;
}

.dropdown-account {
	height: 21px;
	width: 21px;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: contain;
}

.dropdown-setting {
	padding: 5px 10px;
	margin-right: 20px;

	svg {
		stroke: var(--tw-color-gray-900);
	}
}

.dropdown-item:active {
	background-color: #edebec;
	color: unset;
}

.navbar {
	box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
	padding: 12px 0px !important;
}

#navbarToggler .dropdown,
.navbar-nav .dropdown-setting {
	padding-right: 12px !important;
	padding-left: 12px !important;
}

.navbar .dropdown .unread-count {
	top: -4px;
	left: 34px;
	font-size: 12px;
	min-width: 16px !important;
	text-align: center;
	padding: 3px 5px;
	height: 23px;
	width: auto;
	border-radius: 18px;
	background-color: var(--primary-color);
	color: var(--primary-bg-color);
}

.form-control-aux {
	border: none;
	background-color: rgb(241, 241, 242);
}

.border-gr {
	border-color: rgb(1, 224, 171);
}

.text-gr {
	font-family: 'Inter';
	color: rgb(1, 224, 171);
}

.text-green {
	color: var(--success-color) !important;
}
.text-bold {
	font-weight: bold;
}

.hash-customer,
.hash-todo,
.hash-issue,
.hash-post {
	cursor: pointer;
	color: rgb(68, 169, 71) !important;
}

.text-green:hover {
	color: rgb(44, 117, 47);
}

.p-box {
	margin-top: 15px;
	margin-bottom: 10px;

	/* padding: 12px 6px 6px 3px; */
	overflow-x: unset;

	/* width: inherit;  */
}

.todo-box {
	padding: 12px 6px 6px 3px;

	/* overflow-x:hidden; */
	width: inherit;
}

.p-br-bx {
	border: 0.5pt solid var(--border-color-light);
	border-radius: 5px;
	background: var(--primary-bg-color);
}

.pb-create {
	margin-top: 7.25vh !important;

	.p-br-bx {
		margin-left: 50%;
	}
}

.p-pc-bx {
	border: 0.5pt solid var(--border-color-light);
	border-radius: 5px;
	background: #ffffff;
}

.left-bar {
	padding: 0px !important;
	max-width: 330px;
}

.network-info {
	display: block;
	max-width: 240px;
	padding: 12px;
	position: relative;
}

.network-img {
	display: block;
	width: 82px;

	/* height: 80px; */

	img {
		display: block;
		width: 82px;
		height: 82px;
		border-radius: 8px;
	}
}

.network-name {
	font-family: 'Inter';
	font-size: 1.5rem;
	margin-top: 23px;
	overflow-wrap: break-word;

	a {
		color: var(--color-dark);

		&:hover {
			color: var(--color-light);
		}
	}
}

.network-name-2 {
	font-family: 'Inter';
	font-size: 1.5rem;
	margin-top: 7px;
}

.side-nav-bar {
	padding: 15px 0px;

	.nav-items li.nav-item .nav-link {
		padding: 12px 5px;
	}
}

.nav-items {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		padding: 0 12px 0 12px;
		margin: 0;

		&:last-child a {
			border: 0 none !important;
		}

		&.nav-item .nav-link {
			font-size: 14px;
			overflow: hidden;
			max-width: 100%;
			word-wrap: break-word;
			border-bottom: 1px solid var(--border-color-light);
			color: #000000;
		}
	}
}

li.nav-item {
	li.nav-item.active a {
		border: 0 none !important;
		color: var(--primary-color) !important;
		font-weight: 500;
	}

	&.active {
		/* background-color: #e91e631a; */
		border-radius: 4px;
		cursor: pointer;
	}
}

.nav-divider {
	background: url(../assets/img/border.png) repeat-x;
	height: 0.7pt;
	display: block;
	width: 100%;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.pb-title {
	font-family: 'Inter';
	font-size: 21px;
	color: var(--color-dark);
}

.pb-icon {
	width: 27px;
	height: 25px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: bottom;

	&.align-middle {
		vertical-align: middle !important;
	}
}

.pb-icon-lg {
	width: 30px;
}

.pb-icon-md {
	width: 25px;
	background-size: contain !important;
}

.pb-eye-close,
.pb-eye-on {
	width: 30px !important;
	height: 30px !important;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.pb-eye-close {
	background: url(../assets/img/eyeOff.svg) 0 0 no-repeat;
}

.pb-eye-on {
	background: url(../assets/svg/eye.svg) 0 0 no-repeat;
}

.pb-eye-close-1 {
	background: url(../assets/img/eyeChannel.png) 0 0 no-repeat;
	width: 20px !important;
	height: 20px !important;
}

.pb-main {
	background: url(../assets/img/channelPp.jpg) 0 0 no-repeat;
}

.pb-wifi-off {
	background: url(../assets/img/wifi-off.png) 0 0 no-repeat;
	background-size: contain !important;
	width: 20px !important;
	height: 20px !important;
}

.offline-text {
	font-size: 0.9375rem;
	line-height: 1.3333;
}

.pb-wifi-off2 {
	background: url(../assets/img/wifioff.png) 0 0 no-repeat;
	background-size: contain !important;
	width: 20px !important;
	height: 20px !important;
}

.pb-wifi-on {
	background: url(../assets/img/wifion.png) 0 0 no-repeat;
	background-size: contain !important;
	width: 20px !important;
	height: 20px !important;
}

.pb-lock {
	background: url(../assets/img/lock@2x.png) 0 0 no-repeat;
	width: 20px !important;
	height: 20px !important;
}

.pb-unlock {
	background: url(../assets/img/unlock@2x.png) 0 0 no-repeat;
	width: 20px !important;
	height: 20px !important;
}

.pb-video-cam {
	background: url(../assets/img/videoMg.svg) 0 0 no-repeat;
	width: 20px !important;
	height: 20px !important;
}

.pb-video-conferance {
	background: url(../assets/img/videoconferance.png) 0 0 no-repeat;
}

.pb-todolist {
	background: url(../assets/img/todolist.png) 0 0 no-repeat;
}

.pb-startday {
	background: url(../assets/img/startday.png) 0 0 no-repeat;
}

.pb-endday {
	background: url(../assets/img/endday.png) 0 0 no-repeat;
}

/* .pb-home-mg{
      background: url(../assets/img/home.svg) 0 0 no-repeat;
  } */

.pb-home-mg {
	background: url(../assets/img/homeIcon.png) 0 0 no-repeat;
	height: 27px;
}

/* .active .pb-home-mg{
      background: url(../assets/img/homeWhiteNew.svg) 0 0 no-repeat;
  } */

.active .pb-home-mg {
	background: url(../assets/img/homeIcon-w.png) 0 0 no-repeat;
}

.pb-bell-mg {
	background: url(../assets/img/bell-magenta.svg) 0 0 no-repeat;
}

.pb-red-flag {
	background: url(../assets/img/red_flag.png) 0 0 no-repeat;
}

.pb-orange-flag {
	background: url(../assets/img/orange_flag.png) 0 0 no-repeat;
}

.pb-yellow-flag {
	background: url(../assets/img/yellow_flag.png) 0 0 no-repeat;
}

.active .pb-bell-mg {
	background: url(../assets/img/bell-white.svg) 0 0 no-repeat;
}

.pb-users-mg {
	background: url(../assets/img/usersMagenta.svg) 0 0 no-repeat;
}

.pb-count {
	background: url(../assets/img/align-right.svg) 0 0 no-repeat;
	width: 21px;
	height: 15px;
}

.active .pb-users-mg {
	background: url(../assets/img/usersWhite.svg) 0 0 no-repeat;
}

.pb-users-mg-lg {
	background: url(../assets/img/usersMagenta@2x.png) 0 0 no-repeat;
}

.pb-activemembers-lg {
	background: url(../assets/img/usersMagenta@2x.png) 0 0 no-repeat;
	background-size: contain;
	height: 24px;
}

.pb-user-mg {
	background: url(../assets/img/userMagenta.svg) 0 0 no-repeat;
}

.active .pb-user-mg {
	background: url(../assets/img/usersWhite.svg) 0 0 no-repeat;
}

.pb-location {
	background: url(../assets/img/location.svg) 0 0 no-repeat;
}

.pb-user-mg-lg {
	background: url(../assets/img/userMagenta@2x.png) 0 0 no-repeat;
}

.pb-camera-mg {
	background: url(../assets/img/camera.svg) 0 0 no-repeat;
	width: 29px !important;
}

.pb-edit-photo {
	background: url(../assets/img/group2.svg) 0 0 no-repeat;
	width: 29px !important;
	height: 28px !important;
	position: absolute;
	border-radius: 50%;
	border: 2px solid white;
	left: 65px;
	bottom: 10px;
	cursor: pointer;
}

.pb-gallery-mg {
	background: url(../assets/img/new_Gallery_Icon@3x.png) 0 0 no-repeat;
	width: 32px !important;
	height: 26px !important;
}

.pb-doc-mg {
	background: url(../assets/img/new_Attachment_Icon@3x.png) 0 0 no-repeat;
	width: 32px !important;
	height: 29px !important;
}

.pb-loc-mg {
	background: url(../assets/img/new_Location_Icon@3x.png) 0 0 no-repeat;
	width: 32px !important;
	height: 29px !important;
}

.pb-camera-network {
	background: url(../assets/img/group2.svg) 0 0 no-repeat;
}

.active .unread-count {
	color: #ffffff;
}

.todo-users {
	background: url(../assets/img/usersTodo.png) 0 0 no-repeat;
}

.pb-green-arrow {
	background: url(../assets/img/greenarrow.png) 0 0 no-repeat;
	margin-bottom: 7px;
	width: 21px;
	height: 9px;
}

.pb-red-arrow {
	background: url(../assets/img/redarrow.png) 0 0 no-repeat;
	margin-bottom: 5px;
	width: 21px;
	height: 11px;
}

.pb-pastDue-off {
	background: url(../assets/img/pastDueOff.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
	transition: all 0.3s ease;
}

.pb-pastDue-on {
	background: url(../assets/img/pastDue.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
	transition: all 0.3s ease;
}

.pb-priority-off {
	background: url(../assets/img/priorityOff.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.pb-priority-on {
	background: url(../assets/img/priorityTodos.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.pb-todayClosed-off {
	background: url(../assets/img/todayClosedOff.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.pb-todayClosed-on {
	background: url(../assets/img/todayClosed.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.pb-myOpenTasks-off {
	background: url(../assets/img/myOpenTaskOff.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.pb-myOpenTasks-on {
	background: url(../assets/img/myopenTasks.png) 0 0 no-repeat;
	height: 46px;
	width: 46px;
	background-size: contain !important;
	border-radius: 61%;
	box-shadow: 1px 0px 4px #c7afaf;
	margin: 0 auto;
}

.event-image-carousal .pb-camera-network {
	width: 56px;
	height: 52px;
	position: absolute;
	top: 275px;
	right: 30px;
	cursor: pointer;
	z-index: 150;
}

.pb-camera-mg-lg {
	background: url(../assets/img/camera@2x.png) 0 0 no-repeat;
}

.pb-roles-mg {
	background: url(../assets/img/rolesMagenta.svg) 0 0 no-repeat;
}

.pb-image-mg {
	background: url(../assets/img/group8.svg) 0 0 no-repeat;
}

.pb-breifcase {
	background: url(../assets/img/briefcase.svg) 0 0 no-repeat;
}

.pb-graduate {
	background: url(../assets/img/graduate.svg) 0 0 no-repeat;
}

.pb-slash {
	background: url(../assets/img/slash.png) 0 0 no-repeat;
	width: 15px;
	height: 15px;
}

.pb-book {
	background: url(../assets/img/book.svg) 0 0 no-repeat;
}

.pb-chevron-back {
	background: url(../assets/img/chevronRight_1.svg) 0 0 no-repeat;
	width: 10px;
}

.pb-chevron-back3 {
	background: url(../assets/img/chevronBack.png) 0 0 no-repeat;
	width: 10px;
}

.pb-user-check {
	background: url(../assets/img/userCheck.svg) 0 0 no-repeat;
}

.pb-share {
	background: url(../assets/img/share.png) 0 0 no-repeat;
}

.pb-network-mg {
	background: url(../assets/img/networkMagenta.png) 0 0 no-repeat;
}

.pb-map-pin {
	background: url(../assets/img/mapPin.svg) 0 0 no-repeat;
}

.pb-muncipalty {
	background: url(../assets/img/municipality.svg) 0 0 no-repeat;
}

.pb-more {
	background: url(../assets/img/moreWhite.png) 0 0 no-repeat;
}

.pb-more-mg {
	background: url(../assets/img/moreMagenta.svg) 0 0 no-repeat;
	width: 25px;
	height: 5px;
}

.pb-post-close {
	background: url(../assets/img/exit_icon.png) 0 0 no-repeat;
	width: 25px;
	height: 5px;
}

.pb-filter {
	background: url(../assets/img/filterWhite.svg) 0 0 no-repeat;
}

.pb-hash-mg {
	background: url(../assets/img/invalidName.svg) 0 0 no-repeat;
}

.pb-link-mg {
	background: url(../assets/img/link.svg) 0 0 no-repeat;
}

.pb-url-mg {
	background: url(../assets/img/new_Url_Icon@2x.png) 0 0 no-repeat;
}

.pb-attach-mg {
	background: url(../assets/img/attachmentMagenta.svg) 0 0 no-repeat;
}

.pb-mension-mg {
	background: url(../assets/img/mensionMagenta.svg) 0 0 no-repeat;
}

.pb-link-mg-lg {
	background: url(../assets/img/link@2x.png) 0 0 no-repeat;
}

.pb-folder-plus-gr {
	background: url(../assets/img/folderPlusCopy2.svg) 0 0 no-repeat;
}

.pb-folder-minus-mg {
	background: url(../assets/img/folderMinusCopy2.svg) 0 0 no-repeat;
}

.pb-plus-network-mg {
	height: 27px;
	background: url(../assets/img/plusCircle.png) 0 0 no-repeat;
}

.pb-plus-mg {
	height: 27px;
	width: 26.5px;
	background: url(../assets/img/plusCircle@2x.png) 0 0 no-repeat;
}

.pb-volume-x {
	background: url(../assets/img/volumeX@2x.png) 0 0 no-repeat;
	height: 15px;
	width: 15px;
}

.pb-forward {
	background: url(../assets/img/chevronForward.svg) 0 0 no-repeat;
	height: 17px;
	width: 9px;
}

.pb-red-forward {
	background: url(../assets/img/redForward.png) 0 0 no-repeat;
	height: 21px;
	width: 12px;
}

.pb-red-down {
	background: url(../assets/img/redDown.png) 0 0 no-repeat;
	height: 15px;
}

.pb-up {
	background: url(../assets/img/uparrow_bold.png) 0 0 no-repeat;
	height: 31px;
	width: 37px;
	position: relative;
	bottom: 2px;
}

.pb-down {
	background: url(../assets/img/downarrow_bold.png) 0 0 no-repeat;
	height: 31px;
	width: 37px;
	position: relative;
	bottom: 2px;
}

.pb-filter-mg {
	background: url(../assets/img/filterMagenta.svg) 0 0 no-repeat;

	/* height: 20px;
        width: 20px; */
}

.pb-radio-active {
	background: url(../assets/img/active.png) 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
}

.pb-radio-on {
	background: url(../assets/img/on.svg) 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
}

.pb-radio-off {
	background: url(../assets/img/off.svg) 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
}

.pb-eye-mg {
	background: url(../assets/img/eyeMagenta.svg) 0 0 no-repeat;
}

.pb-connetction-mg {
	background: url(../assets/img/connectionMagenta.svg) 0 0 no-repeat;
}

.pb-task-mg {
	background: url(../assets/img/check-1.png) 0 0 no-repeat;
}

.pb-channel-plus {
	background: url(../assets/img/channelPlus.png) 0 0 no-repeat;
}

.pb-checkin {
	background: url(../assets/img/group-3.svg) 0 0 no-repeat;
}

.pb-locShare {
	background: url(../assets/img/locationShare.png) 0 0 no-repeat;
}

.pb-loc-recommend {
	background: url(../assets/img/group-7.svg) 0 0 no-repeat;
}

.pb-project {
	background: url(../assets/img/addProject.png) 0 0 no-repeat;
}

.pb-mail-mg {
	background: url(../assets/img/mailMagenta.svg) 0 0 no-repeat;
}

.pb-bell-off {
	background: url(../assets/img/bellOffMagenta.svg) 0 0 no-repeat;
}

.pb-network-settings {
	width: 24px;
	height: 24px;
}

.pb-back {
	background: url(../assets/img/back.svg) 0 0 no-repeat;
	width: 12px;
	height: 21px;
	transition: all 0.3s ease-in-out;
	opacity: 0.5;

	&:hover {
		transform: scale(1.1);
		opacity: 1;
	}
}

.pb-exit-back {
	background: url(../assets/img/exit_icon.png) 0 0 no-repeat;
	width: 12px;
	height: 21px;
}

.pb-back-white {
	background: url(../assets/img/backWhite.svg) 0 0 no-repeat;
	width: 12px;
	height: 21px;
}

.pb-arrow-up {
	background: url('../assets/img/chevronRight(1).svg') 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
	background-size: contain !important;
	float: right;
	margin-top: 15px;
}

.pb-arrow-down {
	background: url(../assets/img/chevronRightCopy.png) 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
	background-size: contain !important;
	float: right;
	margin-top: 15px;
}

.pb-arrow-down-middle {
	background: url(../assets/img/chevronRightCopy.png) 0 0 no-repeat;
	background-size: contain !important;
	height: 10pt;
	width: 10pt;
	margin-left: 10px;
}

.pb-like {
	background: url(../assets/svg/like.svg) 0 0 no-repeat;
	background-size: contain !important;
	width: 30px !important;
	height: 30px !important;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.pb-liked {
	background: url(../assets/svg/liked.svg) 0 0 no-repeat;
	background-size: contain !important;
	width: 30px !important;
	height: 30px !important;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.pb-comment {
	background: url(../assets/svg/message-text.svg) 0 0 no-repeat;
	background-size: contain !important;
	width: 30px !important;
	height: 30px !important;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.pb-list-mg {
	background: url(../assets/img/listMagenta.svg) 0 0 no-repeat;
}

.pb-h-todolist {
	background: url(../assets/img/todolistIcon.png) 0 0 no-repeat;
}

.pb-issue-mg {
	background: url(../assets/img/ISSUESS_more.png) 0 0 no-repeat;
}

.pb-h-issue {
	background: url(../assets/img/productIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-issue {
	background: url(../assets/img/product-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-customer-mg {
	background: url(../assets/img/customergreen.png) 0 0 no-repeat;
}

.pb-points-mg {
	background: url(../assets/img/pointsIcon.png) 0 0 no-repeat;
}

.pb-h-points {
	background: url(../assets/img/p-Icon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-points {
	background: url(../assets/img/p-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-hash-tag {
	background: url(../assets/img/hashTag.svg) 0 0 no-repeat;
}

.pb-user-mention {
	background: url(../assets/img/userMention.svg) 0 0 no-repeat;
}

.pb-share {
	background: url(../assets/img/share.svg) 0 0 no-repeat;

	/* width: 20px !important; */
	/* margin: 0 !important; */
}

.pb-channel {
	background: url(../assets/img/gridMagenta.svg) 0 0 no-repeat;
}

.pb-h-channel {
	background: url(../assets/img/channelIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-channel {
	background: url(../assets/img/channel-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-h-directory {
	background: url(../assets/img/directoryICon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-directory {
	background: url(../assets/img/directory-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-h-events {
	background: url(../assets/img/eventsIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-events {
	background: url(../assets/img/event-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-h-collection {
	background: url(../assets/img/collectionIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-collection,
.active-link .pb-h-collection {
	background: url(../assets/img/collection-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-h-report {
	background: url(../assets/img/reportIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-report {
	background: url(../assets/img/report-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-h-notifications {
	background: url(../assets/img/notificationiCon.png) 0 0 no-repeat;
	height: 27px;
}

.active {
	.pb-h-notifications {
		background: url(../assets/img/notifi-w.png) 0 0 no-repeat;
		height: 27px;
	}

	.pb-channel {
		background: url(../assets/img/gridWhite.svg) 0 0 no-repeat;
	}
}

.pb-tag {
	background: url(../assets/img/labelMagenta.svg) 0 0 no-repeat;
}

.active .pb-tag {
	background: url(../assets/img/labelWhite.svg) 0 0 no-repeat;
}

.pb-calendar {
	background: url(../assets/img/calendarMagenta.svg) 0 0 no-repeat;
}

.pb-h-calendar {
	background: url(../assets/img/calenderIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-calendar {
	background: url(../assets/img/calender-w.png) 0 0 no-repeat;
	height: 27px;
}

.pb-new-event {
	background: url(../assets/img/newEventIcon.png) 0 0 no-repeat;
}

.pb-new-recommend {
	background: url(../assets/img/newRecommendation.png) 0 0 no-repeat;
}

.pb-new-ques {
	background: url(../assets/img/newQuestionIcon.png) 0 0 no-repeat;
}

.pb-new-sell {
	background: url(../assets/img/newBuysell.png) 0 0 no-repeat;
}

.pb-customers {
	background: url(../assets/img/customerIcon.png) 0 0 no-repeat;
}

.pb-h-customers {
	background: url(../assets/img/customer-Icon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-h-customers {
	background: url(../assets/img/customer-w.png) 0 0 no-repeat;
	height: 27px;
}

/* TODO: remove unused icons CSS and assets */

.pb-locked {
	background: url(../assets/img/kilit.png) 0 0 no-repeat;
	width: 30px;
	height: 32px;
}

.channels-empty {
	background: url(../assets/img/emptyChannels-1.png) 0 0 no-repeat;

	/* width: 30px;
        height: 32px; */
}

.active {
	.pb-issue-mg {
		background: url(../assets/img/issueWhite.png) 0 0 no-repeat;
	}

	.pb-customers {
		background: url(../assets/img/customer-white.png) 0 0 no-repeat;
	}

	.pb-list-mg {
		background: url(../assets/img/list-white.png) 0 0 no-repeat;
	}

	.pb-h-todolist {
		background: url(../assets/img/todolist-w.png) 0 0 no-repeat;
	}
}

.pb-name {
	background: url(../assets/img/path@2x.png) 0 0 no-repeat;
}

.active .pb-calendar {
	background: url(../assets/img/calendarWhite.svg) 0 0 no-repeat;
}

.pb-categories {
	background-size: contain !important;
	background-repeat: no-repeat !important;
	width: 20px;
	height: 20px;
}

.pb-calendar-gr {
	background: url(../assets/img/calendar.svg) 0 0 no-repeat;
}

.pb-cal-gr {
	background: url(../assets/img/calgr.png) 0 0 no-repeat;
}

.pb-clock {
	background: url(../assets/img/clock.png) 0 0 no-repeat;
	width: 22px;
	height: 22px;
}

.pb-repeat {
	background: url(../assets/img/repeat_icon_2.png) 0 0 no-repeat;
	background-size: contain !important;
	height: 26px;
	width: 26px;
}

.pb-dot {
	background: url(../assets/img/dotted.png) 0 0 no-repeat;
	background-size: contain !important;
	height: 26px;
	width: 26px;
}

.pb-rect {
	background: url(../assets/img/rect.png) 0 0 no-repeat;
	background-size: contain !important;
	height: 26px;
	width: 26px;
}

.pb-tasks {
	background: url(../assets/img/task-counts-1.png) 0 0 no-repeat;
}

.pb-question-gr {
	background: url(../assets/img/question.svg) 0 0 no-repeat;
}

.pb-suggestion-gr {
	background: url(../assets/img/fileText.svg);
}

.pb-shareIt-gr,
.pb-issue-gr {
	background: url(../assets/img/shareIt.svg) 0 0 no-repeat;
}

.pb-issue-new {
	background: url(../assets/img/issues_action.png) 0 0 no-repeat;
}

.pb-add-product {
	background: url(../assets/img/add-product-nw.png) 0 0 no-repeat;
}

.pb-message-gr {
	background: url(../assets/img/messageSquare.svg) 0 0 no-repeat;
}

.pb-collection {
	background: url(../assets/img/collection_more.png) 0 0 no-repeat;
}

.pb-report {
	background: url(../assets/img/report.png) 0 0 no-repeat;
}

.active .pb-report {
	background: url(../assets/img/reportWhite.png) 0 0 no-repeat;
}

.pb-project-folder {
	background: url(../assets/img/folderMinusCopy3@2x.png) 0 0 no-repeat;
}

.active {
	.pb-collection {
		background: url(../assets/img/folderWhite.svg) 0 0 no-repeat;
	}

	.pb-points-mg {
		background: url(../assets/img/point-white.png) 0 0 no-repeat;
	}
}

.pb-map-marker {
	background: url(../assets/img/navigationMagenta.svg) 0 0 no-repeat;

	/* width: 21px; */
	/* height: 25px; */
}

.active .pb-map-marker {
	background: url(../assets/img/navigationWhite.svg) 0 0 no-repeat;

	/* width: 21px; */
	/* height: 25px; */
}

.pb-clip-mg {
	background: url(../assets/img/pathCopy.png) 0 0 no-repeat;
	width: 20px;
	background-size: contain !important;
	vertical-align: super;
	display: inline-block;
	margin-left: 12px;
}

.pb-mail-gr {
	background: url(../assets/img/mailGrey.png) 0 0 no-repeat;
	background-size: contain !important;
	width: 22px;
	height: 22px;
}

.pb-message {
	background: url(../assets/img/inboxIcon.png) 0 0 no-repeat;
	height: 27px;
}

.active .pb-message {
	background: url(../assets/img/inboxIcon-w.png) 0 0 no-repeat;
}

.pb-mail {
	background: url(../assets/img/mail.png) 0 0 no-repeat;
	width: 24px;
	height: 19px;
}

.pb-check {
	background: url(../assets/img/checkCircle.png) 0 0 no-repeat;
}

.pb-check-2x {
	background: url(../assets/img/checkCircle.svg) 0 0 no-repeat;
	background-size: auto 20px !important;
	padding: 8px 10px;
	margin-top: 5px;
}

.pb-check-network {
	background: url(../assets/img/checkCircleMagenta.png) 0 0 no-repeat;
}

a.nav-link span {
	display: inline-block;
	padding-left: 10px;
}

.pb-label-gr {
	background: url(../assets/img/labelCopy.svg) 0 0 no-repeat;

	/* width:20px; */
	/* height: 20px; */
}

.pb-postNavigation-mg {
	background: url(../assets/img/navigation.svg) 0 0 no-repeat;
}

.pb-postdoc-mg {
	background: url(../assets/img/new_Attachment_Icon@3x.png) 0 0 no-repeat;
}

.pb-postNavigation-mg-lg {
	background: url(../assets/img/postNavigation@2x.png) 0 0 no-repeat;
}

.pb-share-bl {
	background: url(../assets/img/shareRight.png) 0 0 no-repeat;
}

.pb-shape {
	height: 24px;
	width: 24px;

	/* vertical-align: baseline; */
	/* margin-top: 6px; */
	background: url(../assets/img/shape.svg) 0 0 no-repeat;
}

.pb-close_circle {
	height: 24px;
	width: 24px;

	/* vertical-align: baseline; */
	/* margin-top: 6px; */
	background: url(../assets/img/closeCircle.png) 0 0 no-repeat;
}

.pb-arrow-up-network {
	height: 28px;
	width: 28px;
	background: url(../assets/img/arrow-up-circle-copy.svg) 0 0 no-repeat;
}

.pb-pdf-mg {
	background: url(../assets/img/pdfIcon.svg) 0 0 no-repeat;
}

.pb-audio-mg {
	background: url(../assets/img/audioFileMg.svg) 0 0 no-repeat;
}

.pb-video-mg {
	background: url(../assets/img/videoFileMg.svg) 0 0 no-repeat;
}

.pb-file-mg {
	background: url(../assets/img/fileText.svg) 0 0 no-repeat;
}

.pb-docx-mg {
	background: url(../assets/img/doc.svg) 0 0 no-repeat;
	width: 35px;
	height: 34px;
}

/* .filters {
      padding: 12pt;
  } */

.filters ul {
	padding: 0;
	padding-top: 5px;

	li {
		list-style: none;
		padding-left: 25px;

		span {
			color: var(--color-dark);
		}

		.filter-category {
			width: 100%;
			display: block;
			border-bottom: 1px solid var(--border-color-deep);
			margin-bottom: 5px;
			padding: 16px;
			font-size: 14px;
		}
	}
}

/* Last item bottom border fixer */

.filter-items-scroll > li:last-child .filter-category,
.members-filter-items > li:last-child .filter-category,
.channel-filter-items > li:last-child .filter-category,
.events-filter-items > li:last-child .filter-category,
.noti-filter-items > li:last-child .filter-category {
	border-bottom: none;
}

.filters ul li {
	&.filter-title {
		padding-left: 0px !important;
	}

	span {
		margin-left: 12pt;
	}
}

.post-box-header {
	padding: 9pt 9pt 3pt 15px;

	.user-img {
		display: inline-block;
		vertical-align: top;
		margin-right: 15px;
	}

	textarea {
		display: inline-block;
		width: 82%;
		height: 40px;
		background: rgb(241, 241, 241);
		border: 0 none;
		outline: none;
		border-radius: 4px;
		resize: none;
		padding: 8px;
	}
}

.no-padding {
	padding: 0px !important;
}

.post-box {
	border-bottom: 1px solid #efeaea;
	margin-bottom: 25px;
}

span.user-img-holder {
	display: inline-block;
	width: 50px;
	height: 50px;
}

.user-img-holder img.user-img {
	width: 100%;
	display: block;
}

span.user-info {
	display: inline-block;
	vertical-align: top;
	margin-left: 8px;
}

.user-name {
	display: inline-block;
	vertical-align: top;
	font-size: 16px;
	font-family: 'Inter';
}

.user-role {
	color: rgb(159, 159, 159);
	font-size: 12px;
}

.content {
	display: block;
	padding: 12px;
}

.content-header {
	padding-right: 12px;
	padding-bottom: 12px;
}

.content-title {
	font-size: 16px;
	font-family: 'Inter';
}

.content-role {
	color: var(--color-light);
	font-size: 13px;
	transition: all 0.2s ease-in-out;
}

.content-time {
	font-size: 13px;
	color: var(--primary-color);
}

.unread-count {
	color: var(--primary-color);
	font-size: 15px;
}

.nav-items li.active {
	/* background: rgba(233, 30, 99, 0.75); */
	background-image: linear-gradient(to right top, #ea4b62, #eb4c63, #eb4e63, #ec4f64, #ec5065, #ec5068, #ec506a, #ec506d, #eb5072, #e95078, #e7507d, #e55182);
	color: white;
}

.attachment {
	width: 100%;
	min-height: 287px;
	display: block;
	padding-top: 5px;
	margin-bottom: 30px;
	margin-top: 10px;

	.single-image {
		width: auto;
		height: 345px;
		border-radius: 5px;
		overflow: hidden;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 0.5rem;
		border: 1px solid var(--border-color-light);
	}

	.double-image {
		display: block;
		width: 49.5%;
		height: 287px;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.double-image-1 {
		float: left;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.double-image-2 {
		float: right;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.triple-image-half {
		float: left;
		width: 70%;
		height: 287px;
		overflow: hidden;
		background-size: cover;
		background-repeat: no-repeat;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.triple-image-1 {
		width: 50%;
		height: 100%;
		float: right;
		border-radius: 5px;
	}

	.triple-image-container {
		width: 29%;
		float: right;
		border-radius: 5px;
	}

	.triple-image-top {
		width: 100%;
		overflow: hidden;
		height: 140.6px;
		background-size: cover;
		background-repeat: no-repeat;
		border-top-right-radius: 5px;
	}

	.triple-image-bottom {
		width: 100%;
		margin-top: 5px;
		overflow: hidden;
		height: 140.6px;
		background-color: rgba(0, 0, 0, 0.65);
		background-size: cover;
		background-repeat: no-repeat;
		border-bottom-right-radius: 5px;
		font-size: 32px;
		color: white;
		font-family: Inter;
		padding: 50px;

		div {
			position: absolute;
			z-index: 90;
		}
	}
}

.event-image-container {
	width: 100%;
	border-radius: 5px;
	height: 274px;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	.overlay {
		position: absolute;
		left: inherit;
		height: inherit;
		width: 100%;
		border-radius: inherit;
		z-index: 0;
		bottom: unset;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(252, 252, 252, 0) 100%);
	}
}

.event-post-details {
	padding-top: 9rem;
	padding-left: 20px;
	padding-right: 20px;
	font-family: 'Inter';
	font-size: 19.8px;
	color: white;
	position: relative;
}

.map-box {
	height: 300px;
	width: 100%;
	display: block;
	padding: 0px !important;
}

agm-map.sebm-google-map-container {
	width: 100%;
	height: 300px;
	display: block;
}

.event-post-details {
	.location {
		margin-top: 10px;
	}

	.btn-event {
		color: white;
		border: 2px solid white;
		padding: 2px 20px !important;
		font-size: 17px;
		margin-bottom: 5px;
	}

	.btn-event-active {
		background-color: rgb(12, 215, 170);
		border: 2px solid rgb(12, 215, 170);
		padding: 2px 20px !important;
		font-size: 17px;
		margin-bottom: 5px;
		color: white;
	}
}

/* survey post */

.question-post-details {
	margin-top: 10px;
	color: #000000;

	> div {
		&:first-child {
			margin: 15px 0px;
			height: 15px;
		}

		&:last-child {
			margin: 15px 0px;
			height: 25px;
		}
	}

	.choice {
		width: 100%;

		/* height: 50px; */
		font-size: 18px;
		font-family: 'Inter';
		background-color: #f1f0f2;
		border-radius: 5px;
		margin-top: 10px;
		overflow: hidden;
	}

	span {
		padding-top: 5px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.cursor-disabled {
		cursor: not-allowed;

		* {
			cursor: not-allowed;
		}
	}

	.cursor-active {
		cursor: pointer;
	}

	.dynamic-bg {
		background-color: var(--success-color);
	}
}

.url-attachment {
	display: block;
	border-radius: 5px;
	border: 1px solid var(--border-color-dark);
	margin-bottom: 25px !important;
	margin-top: 10px;

	&:hover {
		.desc-container {
			color: var(--color-dark);
		}
	}

	.preview-container {
		width: 100%;
		height: 220px;
		overflow: hidden;
		text-align: center;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-radius: 5px 5px 0px 0px;

		&.default {
			background-size: cover !important;
		}
	}

	.desc-container {
		color: var(--color-light);
		font-family: 'Inter';
		font-size: 14px;
		padding: 15px;
		transition: all 0.2s ease-in-out;

		&.bg-g {
			background-color: rgba(188, 191, 198, 0.1);
		}

		h4 {
			color: var(--color-dark);
			font-family: 'Inter';
			transition: all 0.2s ease-in-out;
		}
	}
}

.content-footer {
	padding-top: 12px;
	padding-bottom: 12px;

	ul {
		padding: 0;

		li {
			display: inline-block;
			padding-right: 35px;
			color: rgb(159, 159, 159);

			i {
				margin-right: 6px;
				width: 24px;
				height: 21px;
				background-size: contain !important;
			}
		}
	}
}

.content-body p {
	margin-bottom: 8px;
}

.post-box-header span.user-info {
	margin-left: 3px;
	font-size: 14px !important;
	width: 90%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.last-comment {
	.user-name {
		font-size: 14px !important;
	}

	.post-box-header {
		display: flex;
		line-height: 36px;

		span.user-info {
			vertical-align: middle;
			line-height: unset;
		}

		.user-img {
			margin-right: 3px;
		}
	}

	.comment-image {
		max-width: 60%;
		height: 15rem;
		border-radius: 0.375rem;
		object-fit: contain;
		object-position: center;
	}
}

// .child-comment {}

.comments-container .comment-image {
	max-width: 60%;
	height: 15rem;
	border-radius: 0.375rem;
	object-fit: contain;
	object-position: center;
}

.time {
	color: rgb(157, 157, 157);
	font-size: 12px;
}

.last-comment .post-box-header {
	padding: 0px !important;
}

.user-pic {
	width: 25px;
	height: 25px;
	border-radius: 12px;
}

.todo-user-pic {
	width: 36px;
	height: 35px;
	border-radius: 16px;
}

.p-br-bx {
	padding: 12px;
}

.last-comment {
	span.user-info {
		line-height: 1.2;

		.comment-text {
			line-height: 1.5;
			margin-top: 2rem;
		}

		.user-name {
			margin-top: 0px;
			padding: 0px;
		}
	}

	.comment-text {
		font-size: 14px;
		line-height: 1.2rem;
		padding: 0px 7% 1% 7%;
		word-wrap: break-word;

		/* margin-left: 50px; */
	}
}

.center-bar .card {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center;
	height: 235px !important;
	margin: 5px 0;
	cursor: pointer;
	overflow: hidden;
}

.overlay-gr {
	position: absolute;
	bottom: 0px;
	height: 100%;
	width: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(252, 252, 252, 0) 100%);
}

.center-bar {
	.card .footer {
		position: absolute;
		bottom: 1rem;
		color: #ffff;
		text-transform: capitalize;
		padding-left: 12px;
	}

	.network-name {
		margin: 0px !important;
	}

	.card .footer {
		span {
			font-size: 14px;
		}

		h4 {
			margin: 0px !important;
		}
	}
}

.right-bar {
	.card {
		background-size: cover !important;
		border-radius: 5px;
		background-repeat: no-repeat !important;
		height: 80px !important;
		margin: 10px 0;
		padding-left: 20px !important;
		padding-top: 15px !important;
		color: #ffffff;
		cursor: pointer;
	}

	.gradient0 {
		background-image: linear-gradient(to left, #e91e63, #f93e50, #ff5c3d, #ff7b26, #ff9800);
	}

	.gradient1 {
		background-image: linear-gradient(to right, #f8226b, #f42993, #e441bb, #c65adf, #9772fc);
	}

	.gradient2 {
		background-image: linear-gradient(to right, #9772fc, #7a7fff, #5c89ff, #3a92ff, #0299fb);
	}

	.gradient3 {
		background-image: linear-gradient(to right, #0299fb, #00b5ff, #00cfff, #00e6ff, #04fcf9);
	}

	.gradient4 {
		background-image: linear-gradient(to right, #02d5d2, #00e1ca, #00ebb9, #00f4a0, #04fc7f);
	}

	.gradient5 {
		background-image: linear-gradient(to left, #abe001, #94e82f, #7aef4c, #57f665, #04fc7f);
	}

	.gradient6 {
		background-image: linear-gradient(to left, #fcda04, #e9dc00, #d6de00, #c1df00, #abe001);
	}

	.gradient7 {
		background-image: linear-gradient(to right, #fcda04, #ffca00, #ffb900, #ffa900, #ff9800);
	}

	.card span {
		font-size: 16px;
		margin-top: 5px;
	}

	.pb-icon {
		background-size: contain !important;
	}
}

/*Classes for modal*/

.pb-modal {
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 999999 !important;
	backdrop-filter: blur(2px);
	transition: all 0.3s ease-in-out;

	.modal-content {
		border-radius: 13px;
		border: none;
		box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
	}

	.modal-header {
		padding: 0.5em 0.5rem !important;

		.modal-title {
			font-size: 18px;
			font-family: 'Inter';
		}
	}

	.modal-content {
		margin-top: 10vh;
	}

	.modal-body {
		font-size: 16px;
		font-family: 'Inter';
		max-height: 75vh;
		overflow: auto;
	}

	.modal-footer {
		border-top: none;
		padding: 0.5em 0.5rem !important;
		text-align: right;
		display: block;
	}

	.location-footer {
		border-top: none;
		padding: 0.5em 0.5rem !important;
		display: block;
	}

	.modal-footer .btn {
		font-family: 'Inter';
		border: 2px solid var(--primary-color) !important;
		color: var(--primary-color) !important;
		padding: 2px 15px !important;
	}

	.option {
		cursor: pointer;
		height: 28px;
	}

	&.show {
		display: block;
	}
}

/*Classes for post list*/

.post-container {
	width: 100%;
	position: relative;
	margin: 20px 0px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 4%);
	background-color: var(--primary-bg-color);
	color: var(--color-dark);

	&:hover {
		.content-role {
			color: var(--color-dark);
		}
	}

	&.selected-post {
		margin-top: -2%;
		margin-left: -1%;
		padding: 10px;
		z-index: 1025;
		width: 97.5%;
		border-radius: 10px;
		position: absolute;
		background-color: var(--primary-bg-color);
	}

	.post-header {
		width: 100%;

		.btn-options {
			float: right;
			padding-right: 0px;

			svg {
				path {
					stroke: var(--color-dark);
				}
			}
		}

		.profile-container b {
			cursor: pointer;
		}

		.profile-image {
			width: 50px;
			height: 50px;
			border-radius: 25px;
			float: left;
			background-color: #f20057;
			color: white;
			margin-right: 15px;
		}
	}

	.content-footer {
		padding-bottom: unset !important;

		ul li i {
			margin-right: 0px;
		}
	}

	.post-header {
		.content {
			padding-top: 0px;

			b {
				font-size: 16px;
				padding: 0px;
				display: block;
				padding: 0.5rem 0px 0px 0px;
				line-height: 1rem;
				font-family: 'Inter';
			}
		}

		.feed-avatar {
			margin-right: unset !important;
			border: 1px solid var(--border-color-light);
			object-fit: cover;
		}

		.content {
			width: 80%;
			float: left;
		}
	}

	.header .content profile-container {
		max-width: 80%;
		float: left;
		word-wrap: break-word;
		overflow: hidden;
	}

	.post-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 5px;

		.content small {
			font-size: 12px;
			padding: 0px;
			margin: 0px !important;
			font-family: 'Inter Light';
			color: rgb(159, 159, 159);
		}

		.edit-img {
			float: right !important;
			width: 20px;
			height: 4px;
			margin-top: 0px;
			padding-top: 0px;
			cursor: pointer;
		}

		.user {
			display: flex;
			flex-direction: row;
			column-gap: 10px;

			.user-info {
				display: flex;
				flex-direction: column;
				align-self: center;
				align-items: flex-start;
				height: 38px;
				font-weight: 600;
			}
		}
	}

	.post-body {
		ol,
		ul,
		dl {
			li {
				list-style: unset;
			}
		}

		pre {
			display: block;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.title {
			font-family: 'Inter';
			font-size: 16px;
			margin-bottom: 5px !important;
			padding-bottom: 0px;
		}

		.description-container {
			margin-bottom: 1rem;
			word-wrap: break-word;
		}

		.privacy-time-container {
			margin-bottom: 0.1rem !important;
		}

		.description {
			font-family: 'Inter';
			font-size: 14px;
			line-height: 1rem;
			padding-bottom: 0px;
			display: block;
			/* margin-bottom: 5px !important; */
		}
		.description-trucate {
			font-family: 'Inter';
			font-size: 14px;
			line-height: 1rem;
			padding-bottom: 0px;
			display: block;
			height: 200px;
			overflow-y: hidden;
		}

		.welcome-post {
			border-radius: 5px;
			height: 70px;
			border: 1px solid rgb(188, 191, 198);
			padding: 14px;

			h4 {
				margin-top: 1%;
				padding-bottom: 1px;
				margin-bottom: 0px;
			}

			#imageCarousal {
				height: 200px;
				width: 100px;

				.btn-post.active {
					border-color: var(--primary-color) !important;
					background-color: var(--primary-color) !important;
					color: white !important;
					font-weight: 500;
				}
			}

			.picsum-img-wrapper {
				img {
					height: 200px;
					width: auto;
					overflow: hidden;
				}
			}
		}

		.hide {
			height: 28px;
			overflow: hidden !important;
		}

		.show {
			height: auto;
			overflow: visible !important;
		}

		.read-more {
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;
			color: var(--primary-500);
		}

		.content-time {
			color: var(--primary-color) !important;
		}
	}
}

.minimal-breaker {
	padding: 0px !important;
	margin-top: 2px;
	margin-bottom: 0px;
	background-color: var(--border-color-deep);
}

#navbarToggler .dropdown {
	margin-right: 0px !important;
	padding-right: 0px !important;
	z-index: 1026;

	.dropdown-toggle {
		margin-right: 0px !important;
		padding-right: 0px !important;
	}
}

.feed-avatar {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	float: left;
	margin-right: 15px;
}

#header-nav-profile-icon {
	margin-right: 0px !important;
	padding-right: 0px !important;
}

.post,
.post-container {
	input {
		border: none;
		background-color: #f1f0f2;
	}

	.author {
		display: flex;
		flex-direction: row;
		row-gap: 10px;
		align-items: center;

		.username {
			display: flex;
			flex-direction: column;
			column-gap: 5px;
			margin-left: 15px;
		}
	}

	.post-attachment {
		padding: 5px;
		cursor: pointer;
	}
}

.user-img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid var(--border-color-dark);
	object-fit: cover;
}

span.counts {
	font-size: 14px;
	color: rgb(159, 159, 159);
}

.feed-box {
	margin-bottom: 15px;
	position: relative;
}

.post-list-dropdown {
	position: absolute;
	z-index: 90;
	border-radius: 4px;
	border: 1px solid #cecece;
	font-size: 14px;
	background-color: white;
	color: var(--primary-color);
}

.event-dropdown {
	width: 100px !important;
	margin-left: 135px !important;
}

.post-dropdown-menu {
	cursor: pointer;
	color: var(--primary-color);
	font-size: 14px;
	font-family: 'Inter';
}

.filter-container {
	float: right !important;

	/* width: 100% !important; */
}

.float-right {
	float: right !important;
}

.float-left {
	float: left !important;
}

.post-list-container {
	float: left !important;

	/* width: 100%; */
	margin-top: 0px !important;
	padding-top: 0px !important;
	line-height: 1.25;

	/*Need to remove if not appropriate*/
	min-height: 70vh;
	position: relative;
}

.create-post-container {
	cursor: default !important;
	float: left !important;
	margin-bottom: 0px;
	border-bottom: none !important;
	padding-bottom: 0px !important;

	.pb-icon {
		cursor: default !important;
	}

	.post {
		padding-bottom: 10px;
	}

	.post-active {
		background-color: rgba(0, 0, 0, 0.2);
	}

	.post {
		.post-form {
			min-height: 88px !important;
		}

		.details {
			margin-top: 10px;
		}

		.btn-post {
			color: var(--primary-color);
			border: 2px solid var(--primary-color);
			padding: 2px 20px !important;
			font-size: 17px;
			margin-bottom: 5px;
			float: right;
		}
	}
}

.member-attachment-role {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	display: block;
}

.create-post-container .p-br-bx {
	border-bottom: none;
	border-radius: 5px 5px 0px 0px;
}

.post-list-container {
	.p-br-bx {
		border-top: none;
		border-radius: 0px 0px 5px 5px;
	}

	.hash-tag-info .badge {
		border-radius: 10px;
		color: white;
		font-size: 1rem;
		padding: 2px 5px;
		background-color: var(--primary-color);
		cursor: pointer;
	}
}

.post-comment {
	padding-top: 20px;
	padding-bottom: 10px;
	margin-top: 15px;
	border-top: 1px solid rgb(179 179 179 / 20%);
}

.backdrop {
	position: fixed;
	z-index: 1025;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
	height: 100%;
	width: 100%;
}

.comment-header {
	width: 100%;

	.feed-avatar {
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}
}

.comments-container {
	width: 100%;

	.user-img-holder {
		width: 36px;
		height: 36px;
		border-radius: 18px;
	}

	.content-body {
		width: 100%;
		overflow: hidden;
		word-wrap: break-word;
		padding: 0px 7% 0.1% 7%;
		font-family: 'Inter';
		font-size: 14px;

		.more-label {
			display: inline-block;

			/* width: 46px; */
			color: var(--primary-color);
			cursor: pointer;
		}
	}

	.content-header {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.content-footer {
		padding-left: 7%;
		color: rgb(159, 159, 159);
		font-family: 'Inter';
		font-size: 13px;

		.reply-comment-container {
			/* padding-left: 5%; */
			margin-top: 5%;
		}

		.comment-time {
			display: inline-block;
			min-width: 46px;
		}

		.comment-like {
			display: inline-block;
			width: 3.75rem;
		}

		.comment-reply {
			display: inline-block;

			/* width: 46px; */
			color: var(--primary-color);
			cursor: pointer;
		}
	}

	.content-body .text-reply {
		color: #066096;
	}
}

.last-comment .content-footer {
	font-size: 12px;
	line-height: 1.2rem;

	/* padding: 0px 5.1% 1% 9.1%; */

	.reply-comment-container {
		padding-left: 5%;
		margin-top: 5%;
	}

	.comment-time {
		display: inline-block;
		min-width: 46px;
	}

	.comment-like {
		display: inline-block;
		width: 46px;
	}

	.comment-reply {
		display: inline-block;

		/* width: 46px; */
		color: var(--primary-color);
		cursor: pointer;
	}
}

.comments-container {
	.content-footer .reply-count,
	.pb-like,
	.pb-liked {
		float: right;
	}

	.user-name {
		display: block;
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.user-role {
		margin-top: 0px;
		padding-top: 0px;
		display: block;
	}

	.pb-arrow-down {
		margin-top: 5px;
		padding-left: 5px;
		width: 15px;
		height: 9px;
	}
}

/*===================*/
/* Notification list*/

li.gap {
	margin-top: 15px;
}

.feed-avatar .user-pic {
	width: 60px;
	height: 60px;
	border-radius: 30px;
}

.feed-info {
	display: inline-block;
	border-bottom: 1px solid #ddd;
	width: 87%;
	padding-left: 10px;
	vertical-align: top;
	padding-bottom: 10px;
	font-size: 15px;
	font-family: 'Inter';
}

.feed-avatar {
	display: inline-block;
	vertical-align: text-bottom;
}

span.feed-name {
	color: var(--primary-color);
	font-size: 14px;
}

.feed-content span.time {
	float: right;
}

span.unread {
	position: absolute;
	left: 64px;
	width: 12px;
	height: 12px;
	background: var(--primary-color);
	top: 5px;
	border-radius: 10px;
}

.time-unread,
.channel-name {
	color: var(--primary-color) !important;
}

.pos-r {
	position: relative;
}

.color-orange {
	color: var(--primary-color) !important;
}

/*=======================*/
/*Classes for buy/sell*/

.buy-sell-container {
	width: 100%;
	overflow: hidden;

	.tile {
		width: 50% !important;
		padding: 5px;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		&.map {
			height: auto;

			/* border: 1px solid #B5B4BA; */
			border-radius: 15px;
			width: 100%;
			overflow: none;
		}
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.tile {
		img {
			height: auto;
			border: 1px solid #b5b4ba;
			border-radius: 15px;
			width: 100%;
		}

		.price {
			height: 100%;
			width: 96%;
			z-index: 100;
			margin: 5px;
			border-radius: 15px;
			font-size: 16px;

			/* background-image: linear-gradient(0deg, rgba(0,0,0,.65) 0%, rgba(0,0,0,.35) 4%, rgba(0, 0, 0, 0) 100%); */
			font-family: 'Inter';
			position: absolute;
			bottom: 0px;
			left: 0px;
			color: white;

			span {
				position: absolute;
				bottom: 5px;
				left: 5px;
			}
		}
	}
}

/*=======================*/
/*Classes for member lsiting page*/

.member-container {
	min-height: 50px;
	margin-bottom: 0px;

	.avatar {
		.user-pic {
			width: 43px;
			height: 43px;
			border-radius: 20px;
			position: relative;
		}

		.channels-pic {
			width: 50px;
			height: 49px;
			border-radius: 24px;
			position: relative;
		}
	}

	.user-name {
		font-size: 15px;
		font-family: 'Inter';
	}

	.role {
		font-size: 12px;
		font-family: 'Inter';
		color: rgb(159, 159, 159);
	}

	.department {
		margin-top: 10px;
		font-size: 12px;
		font-family: 'Inter';
		color: rgb(159, 159, 159);
	}

	.user-detail,
	.breaker-container {
		margin-bottom: 0px;
	}
}

.dropdown-toggle::after {
	visibility: hidden;
}

/************channel list*********/

.channel-name {
	font-size: 16px;
	font-family: 'Inter';
}

.channel-info {
	color: #000000;
	font-size: 14px;
}

.no-pad {
	padding: 0;
	margin-bottom: 10px;
}

span.postcount {
	position: absolute;
	top: 17px;
	left: -3px;
	background: var(--primary-color);
	border-radius: 10px;
	padding: 1px 6px;
	font-size: 10px;
	width: auto;
	border: 2px solid #ffff;
	height: 18px;
	color: #fff;
}

/*******location list************/

.loc-avatar {
	background: #fbf7f7;
	width: 72px;
	height: 72px;
	display: block;
	border-radius: 5px;
	padding: 12px 12px;
	border: 1px solid #efeeee;

	.user-pic {
		width: 98px !important;
		height: 86px !important;
		border-radius: 10px;
	}
}

span.rating-count {
	position: absolute;
	right: 16px;
	top: 5px;
	width: 35px;
	height: 22px;
	background: var(--primary-color);
	padding: 0 13px;
	color: #fff;
	border-radius: 5px;
}

.location-container .user-detail {
	position: relative;

	/* padding-left: 20px; */
}

.distance {
	margin-top: 20px;
}

li.gap {
	hr {
		margin: 0;
	}

	margin-top: 40px;
}

.join-networks {
	.btn-post {
		color: var(--primary-color);
		border: 2px solid var(--primary-color);
		padding: 2px 20px !important;
		font-size: 17px;
		width: 92%;
		margin-bottom: 5px;
	}

	.content {
		min-height: 297px;

		img {
			height: 35px;
			width: 35px;
		}

		.network {
			min-height: 48px;
			cursor: pointer;
		}

		.name {
			padding: 5px auto;
		}
	}
}

/*****Notifications*******/

.notification {
	max-width: 360px;
	min-height: 122px;
	max-height: 80vh;
	overflow-y: auto;
	width: 335px;
	right: 0;
	left: auto !important;

	.dropdown-item {
		color: var(--color-dark);
		transition: all 0.2s ease;
	}

	&.full {
		width: 509px !important;
	}

	.user-pic-container {
		width: 30%;
	}

	.user-pic {
		height: 30px;
		width: 30px;
		border-radius: 15px;
	}

	.title {
		width: 80px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.content {
		font-size: 12px;
		width: 250px;
		padding-top: 2px !important;
		white-space: initial;
		overflow: hidden;
	}
}

.noti-btn-cntr {
	float: left;
	width: 100%;
	text-align: center;
}

.notification {
	.btn-post {
		color: var(--primary-color);
		border: 2px solid var(--primary-color);
		padding: 2px 20px !important;
		font-size: 17px;
		margin-bottom: 5px;
	}

	.unread {
		top: 20px;
		left: 12px;
	}
}

/****Login and signup page****/

#auth-container {
	width: 100%;
	padding-right: 15px;
	margin-right: auto;
	margin-left: auto;
	background-color: #ffffff;
	position: absolute;
	height: 100vh;
	margin: 0;
	top: 0;
	left: 0;

	.auth-image {
		width: 50vw;

		.text {
			color: white;
			width: 70%;
			font-size: 1.5em;
			margin-top: 6%;
			font-family: 'Inter';

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.form-container {
		margin-top: 12%;
		margin-left: 21.6%;
		margin-right: 21.6%;

		.title {
			margin-top: 2.4%;
			margin-bottom: 2.6%;
			font-size: 20px;
			font-family: 'Inter';
		}

		.pb-icon {
			vertical-align: middle !important;
		}

		.mt {
			margin-top: 2.4%;
		}

		button {
			margin-top: 3%;
			width: 100%;
			font-size: 1.5rem;
		}
	}
}

/* #auth-container .auth-image{
      width: 100%;
      height: 100%;
      margin-top: 20vh;
      background-image: url(../../assets/img/Loginscreen.png) ;
      background-repeat:no-repeat; 
      background-size: contain; 
  } */

@media (max-width: 768px) {
	#auth-container {
		padding-right: 0;

		.form-container {
			margin-left: 8.6%;
			margin-right: 8.6%;
		}

		.otp-container {
			margin-left: 5.6%;
			margin-right: 5.6%;
			margin-top: 30%;
		}
	}
}

@media (min-width: 1200px) {
	.join-err {
		margin-left: -47px;
	}
}

/****Classes for user profile page***/

.user-container {
	.image-container {
		background-image: url(../assets/img/rectangle3.png);
		background-size: cover;
		background-repeat: no-repeat;
		height: 200px;
		width: 100%;

		.user-pic {
			width: 122px;
			height: 122px;
			border: 3px solid white;
			border-radius: 50%;
			position: absolute;
			top: 100px;
			left: 5px;
		}
	}

	.settings-icon {
		padding: 5px;
		height: 30px;
	}

	.detail-continer {
		padding-top: 30px;
		border-top: none;
		border-top-right-radius: unset;
		border-top-left-radius: unset;
	}

	.user-name {
		font-size: 18px;
		font-style: 'Inter';
	}

	.status-container {
		font-size: 14px;
		font-family: 'Inter Light';
		color: rgb(159, 159, 159);
	}

	.msg-btn {
		border: 2px solid var(--primary-color);
		font-style: 'Inter';
	}

	.user-description {
		font-size: 16px;
	}

	.profile-details {
		margin: 10px;

		.detail {
			margin: 10px auto;
		}

		.tab-heading {
			padding: 10px;
			font-size: 16px;
			cursor: pointer;
		}

		.detail {
			padding-bottom: 10px;
		}
	}

	&.settings .settings-header {
		padding: 10px;
		font-size: 20px;
		font-family: 'Inter';
	}

	.settings-header .btn {
		padding: 2px 10px;
	}

	&.settings .status-container {
		font-size: 12px;
		color: black;
	}
}

/*****Create Post Container*******/

.create-post-btn {
	position: fixed;
	bottom: 2%;
	right: 9%;
	text-align: center;
	height: 74px;
	width: 74px;
	font-size: 45px;
	color: white;
	background-color: var(--primary-color);
	border-radius: 42px;
	box-shadow: 0px 0px 78px -6px rgba(0, 0, 0, 0.75);
	cursor: pointer;
	z-index: 1020;
	animation-name: reverse-rotation;
	animation-duration: 0.5s;
	transform: rotate(0deg);

	/* background-image: linear-gradient(
        to right top,
        #ef4c5e,
        #f1534e,
        #f05d3e,
        #f27339,
        #e77316
      ); */
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	transition: all 0.5s ease-in-out;

	&:hover {
		transform: scale(0.97);
	}

	&.active {
		animation-name: rotation;
		animation-duration: 1s;
		transform: rotate(225deg);
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(225deg);
	}
}

@keyframes reverse-rotation {
	from {
		transform: rotate(225deg);
	}

	to {
		transform: rotate(0deg);
	}
}

/******Post create form*********/

.pb-create-form {
	position: fixed;
	z-index: 1050;
	top: 7%;
	width: 584px;
	left: auto;
	right: auto;
	padding-left: 1%;

	.title {
		font-family: 'Inter';
		font-size: 18px;
		min-height: 25px;
	}
}

.pb-create .title {
	font-family: 'Inter';
	font-size: 18px;
	min-height: 2.5rem;
}

.pb-create-form .close {
	float: right;
	font-size: 18px;
	color: rgba(0, 0, 0, 0.25);
}

.pb-create .close {
	float: right;
	font-size: 18px;
	cursor: default;
}

.form-control,
.pb-create-form .form-control,
.pb-create .form-control,
.pb-createform .post-textarea,
.pb-create .post-textarea {
	border: 1px solid var(--border-color-light);
	background-color: var(--input-backgorund);
	transition: all 0.2s ease-in-out;

	&:focus {
		background-color: var(--primary-bg-color);
		border: 1px solid var(--primary-color-light) !important;
	}
}

.pb-create-form .description,
.pb-create .description {
	height: auto;
	min-height: 5rem !important;
}

.pb-create-form .post-icons-container,
.pb-create .post-icons-container {
	margin-top: 5px;
	vertical-align: baseline;
}

.pb-create-form .btn-post,
.pb-create .btn-post {
	float: right;
	font-family: 'Inter';
	border: 1px solid var(--primary-color);
	padding-left: 20px;
	padding-right: 20px;
	color: var(--primary-color);
}

.pb-create-form {
	.p-box {
		padding-top: 0px;
	}

	.post-icons-container .pb-icon {
		width: 21px;
		height: 21px;
		margin-right: 5px;
		background-size: contain !important;
	}
}

.pb-create .post-icons-container .pb-icon {
	width: 21px;
	height: 21px;
	margin-right: 5px;
	background-size: contain !important;
}

.pb-create-form .member-container,
.pb-create .member-container {
	width: 100%;
	min-height: 50px;
	border: none;
	border-radius: 5px;
	background-color: rgb(241, 241, 242);
	padding: 20px;
}

.pb-create-form .location-container {
	border-radius: 10px;
}

.pb-create {
	color: var(--color-dark);

	.location-container {
		border-radius: 10px;

		.close {
			position: absolute;
			top: -10px;
			right: -5px;
			padding: 2px 6px;
			background-color: white;
			color: var(--primary-color);
			z-index: 100;
			font-size: 20px;
			border-radius: 15px;
			-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
			box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
			cursor: pointer;
		}
	}

	.event-image-carousal .close {
		position: absolute;

		/* top: 72px; */
		right: 20px;
		padding: 2px 6px;
		background-color: white;
		color: var(--primary-color);
		z-index: 100;
		font-size: 20px;
		border-radius: 15px;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
		cursor: pointer;
	}
}

.pb-create-form .category-container,
.pb-create .category-container {
	padding: 5px 15px;
	width: 100%;

	/* text-align: center; */
	font-family: 'Inter';
}

.pb-create-form .category-container .category {
	background-color: #ececec;
	width: 47%;
	padding: 5px 15px;
	border-radius: 5px;
	margin: 2px;
	text-align: left;
	display: inline-block;
	cursor: pointer;
}

.location-modal .location-search .close {
	position: absolute;
	top: 10px;
	right: 23px;
	font-size: 16px;
	color: var(--primary-color);
	cursor: pointer;
}

.pb-create .category-container .category {
	background-color: #ececec;
	width: 47%;
	padding: 5px 15px;
	border-radius: 5px;
	margin: 2px;
	text-align: left;
	display: inline-block;
	cursor: pointer;
}

.pb-create-form .free-container,
.pb-create .free-container {
	height: calc(2.25rem + 2px);
	width: 100%;
	border-radius: 5px;
	background-color: rgb(241, 241, 242);
}

.pb-create-form .free-container .btn,
.pb-create .free-container .btn {
	width: 49%;
	height: 100%;

	/* float:left; */
	background-color: white;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.pb-create-form .free-container .btn.left,
.pb-create .free-container .btn.left {
	float: left;
}

.pb-create-form .free-container .btn.right,
.pb-create .free-container .btn.right,
.pb-create-form .free-container .label.right,
.pb-create .free-container .label.right {
	float: right;
}

.pb-create-form .free-container .label.left,
.pb-create .free-container .label.left {
	float: left;
}

.pb-create-form .free-container .label {
	width: 49%;
	text-align: center;
	font-size: 1.1rem;
	padding: 5px;
	font-family: 'Inter';
}

.pb-create {
	.free-container .label {
		width: 49%;
		text-align: center;
		font-size: 1.1rem;
		padding: 5px;
		font-family: 'Inter';
	}

	.choice-container .add-new-choice {
		font-family: 'Inter';
		font-size: 16px;
		color: var(--primary-color);
	}
}

/******File upload class*******/

.dragarea {
	border: 1px dashed #b3b3b3;
	color: #c0c0c0 !important;
	width: 100%;
	font-size: 22px;
	min-height: 90px;
	margin: 15px 0px;
	padding: 5px 10px;
	border-radius: 8px;

	a {
		color: #c0c0c0;
		font-size: 19px;
	}
}

.image_container {
	display: flex;
	padding: 0px;
	margin-top: 20px;

	ul {
		padding: 0px;

		&.preview_img {
			position: relative;

			li {
				list-style: none;
				float: left;
				margin: 5px;
				text-align: center;
				position: relative;

				img {
					height: 100px;
					width: 165px;
					border: 1px solid #e1e1e1;
					border-radius: 5px;
					object-fit: cover;
				}

				.name {
					font-size: 10px;
					color: black;
					width: 100px;
					display: inline-block;
					margin: 8px 5px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.btn-close {
					position: absolute;
					background: none;
					right: -5px;
					top: -14px;
					background-color: var(--primary-color);
					border: 1px solid white;
					padding: 0px;
					height: auto;
					width: auto;
					border-radius: 100%;
					transition: all 0.2s ease-in-out;

					svg {
						height: 17px;

						path {
							fill: white;
						}
					}
				}
			}
		}
	}
}

/*****Style for Privacy Modal******/

.pb-create {
	position: absolute;
	z-index: 1050;
	top: 0;
	left: 0;
}

.post-create {
	/* position:absolute;  */
	/* z-index: 1050;  */
	top: 0;
	left: 0;

	.form-control {
		border: none;
		background-color: rgb(241, 241, 242);
	}
}

.pb-privacy {
	.pb-arrow-down {
		margin-top: unset;
	}

	.user-pic {
		width: 50px;
		height: 50px;
		border-radius: 25px;
	}

	.user-name {
		background-color: var(--primary-color);
		width: 50px;
		height: 50px;
		font-size: 1.7rem;
		border-radius: 25px;
		text-align: center;
		padding: 7px;
		color: white;
		background-size: cover;
		text-transform: uppercase;
	}

	.name {
		font-size: 16px;
		font-family: 'Inter';
	}

	small {
		padding: 0px;
	}

	.members .btn-post {
		float: unset;
	}

	#special-choices #member-container {
		max-height: 35vh;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

/********Style for custom switch******/

.onoffswitch {
	position: relative;
	width: 90px;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 2px solid #f1f0f2;
	border-radius: 20px;
}

.onoffswitch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;

	&:before,
	&:after {
		display: block;
		float: left;
		width: 50%;
		height: 30px;
		padding: 0;
		line-height: 30px;
		font-size: 14px;
		color: white;
		font-family: Trebuchet, Arial, sans-serif;
		font-weight: 500;
		box-sizing: border-box;
	}

	&:before {
		content: '';
		padding-left: 10px;
		background-color: var(--success-color);
		color: #ffffff;
	}

	&:after {
		/* content: "OFF"; */
		content: '';
		padding-right: 10px;
		background-color: #eeeeee;
		color: #999999;
		text-align: right;
	}
}

.onoffswitch-switch {
	display: block;
	width: 18px;
	margin: 6px;
	background: #ffffff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 56px;
	border: 2px solid #f1f0f2;
	border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
	.onoffswitch-inner {
		margin-left: 0;
	}

	.onoffswitch-switch {
		right: 0px;
	}
}

/**Channel Classes**/

.channel-detail .user-pic {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

/**User suggestion classes**/

.user-suggestion {
	.user-pic {
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}

	.dropdown-item {
		color: black !important;
		font-size: 1.1rem;

		&:hover {
			background: var(--primary-color);
		}
	}
}

.post-attachment .pb-icon {
	width: 28px;
}

.post-comment {
	.attachment-icons {
		display: none;
	}

	.form-control {
		border: none;
		background-color: var(--input-backgorund);
		color: var(--color-dark);
	}

	.close-edit-comment {
		color: var(--primary-color);
		background-color: white;
		padding: 1px 5px;
		font-size: 13px;
		border-radius: 10px;
		top: -9px;
		position: absolute;
		left: 95%;
		cursor: pointer;
		-webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
	}

	.attachment-icons {
		&.show {
			display: block;
		}

		.pb-link-mg {
			width: 20px !important;
			height: 22px !important;
			vertical-align: top;
		}

		.pb-hash-mg {
			height: 23px !important;
			vertical-align: top !important;
		}

		.pb-postNavigation-mg {
			width: 20px !important;
			height: 20px !important;
			vertical-align: top;
		}

		.pb-icon {
			width: 25px;
			height: 25px;
			background-size: contain !important;
		}
	}
}

.pt-m {
	padding-top: 0.1px !important;
}

.pb-b {
	padding-bottom: 0.1px !important;
}

/*******Buy sell detail page CSS******/

.buy-sell-detail {
	.pb-title {
		font-size: 18px;
	}

	.pb-share-bl {
		float: right;
	}

	.tab-container {
		font-size: 1.2rem;
	}

	.image-container {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.p-tb-bx {
	padding: 12px 0px;
}

.buy-sell-detail .post-container {
	border-bottom: unset !important;

	.title {
		font-size: 1.2rem;
		font-family: 'Inter';
	}
}

.price-container {
	position: absolute;
	z-index: 100;
	bottom: 10px;
	left: 15px;
	font-size: 1.5rem;
	color: #ffff;
}

.pb-tag-gr {
	background: url(../assets/img/tag.svg) 0 0 no-repeat;
	width: 19.2px;
	height: 19.2px;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;
}

.tab-container .active {
	border-bottom: 1px solid var(--primary-color);
	color: var(--primary-color);
}

.row-mg-fix {
	margin-left: -33px;
	margin-right: -20px;
}

.row-la {
	margin-left: -2px;

	/* margin:0 !important; */
}

.cursor-pointer {
	cursor: pointer !important;
}

.location-pic {
	width: 45px !important;
	height: 45px !important;
	border-radius: 15px;
}

/* CSS Loader*/

.lds-ring {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 51px;
		height: 51px;
		margin: 6px;
		border: 6px solid var(--primary-color);
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: var(--primary-color) transparent transparent transparent;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}

		&:nth-child(2) {
			animation-delay: -0.3s;
		}

		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.pb-edit-modal {
	.modal-dialog {
		height: 90%;
	}

	.modal-content {
		margin-top: 1vh;
	}

	.modal-body {
		max-height: 80vh;
		overflow: auto;
	}

	.modal-footer {
		border-top: 1px solid #dee2e6 !important;
	}

	.edit-image-container {
		margin-top: 18px;

		img {
			width: 100%;
			border-radius: 10px;

			/* border: 1px solid black; */
		}

		.remove-btn {
			position: absolute;
			top: -10px;
			right: 5px;
			width: 21.4px;
			padding: unset;
			height: 21.4px;
			border-radius: 50%;
			text-align: center;
			vertical-align: middle;
			background-color: #f1f1f2;
			vertical-align: middle;
			cursor: pointer;
			border: solid 0.5px rgba(0, 0, 0, 0.1);
			font-family: 'Inter';
			color: #9f9f9f;
		}
	}
}

/**Post Edit modale **/
@media (min-width: 640px) {
	.pb-edit-modal {
		.modal-dialog {
			width: 80vw;
			max-width: unset;
		}

		.modal-content {
			width: 80vw;

			/* margin-top: 2vh; */
		}
	}
}

@media (min-width: 790px) {
	.pb-edit-modal {
		.modal-dialog {
			width: 50vw;
			max-width: unset;
		}

		.modal-content {
			width: 50vw;

			/* margin-top: 3vh; */
		}
	}
}

/* @media (min-width: 768px){
          .pb-edit-modal .modal-dialog{
              width : 40vw;
              max-width: unset;
          }
          .pb-edit-modal .modal-content{
              width : 40vw;
              margin-top: 5vh;
          }
      } */
@media (min-width: 1200px) {
	.pb-edit-modal {
		.modal-dialog {
			width: 35vw;

			/* max-width: unset; */
		}

		.modal-content {
			width: 35vw;

			/* margin-top: 5vh; */
		}

		.pb-new-post {
			width: 38vw;
		}
	}
}

/**Recomendation Slider**/

.slidecontainer {
	width: 100%;
}

.slider {
	appearance: none;
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	border-radius: 5px;
	background-image: linear-gradient(272deg, #ff9800, #e91e63);
	outline: none;
	opacity: 1;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
}

.pb-create {
	.your-rating-label {
		font-size: 16px;
		margin-bottom: 10px;
		margin-bottom: 10px;
		font-family: 'Inter';
	}

	.range-container {
		font-size: 20px;
		font-family: 'Inter';
		color: var(--primary-color);
	}
}

.slider {
	&:hover {
		opacity: 1;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		transition: all 1s;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
	}

	&::-moz-range-thumb {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		transition: all 1s;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
	}
}

.welcome-post.bg-g {
	background-color: rgba(188, 191, 198, 0.1);
}

.btn-grey {
	border-color: gray;
	color: gray;
	font-weight: 500;

	&:disabled {
		cursor: not-allowed;
	}
}

/* Do not change position */

.btn-lipstick {
	border-color: var(--primary-color);
	color: var(--primary-color);
	padding: 2px 6px;
	font-family: 'Inter';

	&:hover {
		color: white;
		background-color: var(--primary-color);
	}

	&:disabled {
		cursor: not-allowed;
		color: var(--primary-color);
		background-color: white !important;
	}
}

/* Do not change position */

/* Do not change position */

.welcome-text {
	color: var(--primary-color);
	margin-top: 10px;
	font-family: 'Inter';
}

.btn-green {
	background-color: green;
}

.text-underline:hover {
	text-decoration: underline;
}

.pending-text {
	position: absolute;
	text-align: right;
	bottom: 8px;
	right: 16px;
	color: white;
}

.text-blue {
	color: blue;
	text-decoration: underline;
}

.bottom-nav-bar {
	display: none;
}

ul.network-more-nav {
	position: absolute;
	background: #fff;
	border: 1px solid #cbcbcb;
	right: 40px;
	width: 55%;
	bottom: 55px;
}

.network-more-nav ul li {
	width: 100%;
	display: block !important;
	padding: 5px !important;
}

/*Small devices (landscape phones, 576px and up)*/
@media only screen and (max-width: 600px) {
	side-bar {
		display: none;
	}

	.p-box {
		width: 100%;
	}

	.p-br-bx {
		margin-left: 33px;
	}

	.new-message {
		display: none;
	}

	.right-bar {
		margin-left: 17px !important;
		margin-right: 15px !important;
	}

	.left-bar {
		display: none !important;
	}

	app-post.row-mg-fix {
		margin-left: -40px !important;
		margin-right: -15px !important;
	}

	a.nav-link .unread-count {
		padding: 0px 2px !important;
		position: absolute;
		left: 12px;
		top: 5px;
		background: #fff;
		color: #ef568a;
		border-radius: 10px;
		font-size: 10px;
		border: 1px solid #ef568a;
	}

	.filter-container {
		position: fixed;
		top: 46px;
		border: 0;
		z-index: 10000;
		width: 100vw !important;
		right: 17px;
		display: none;
	}

	.create-post-container .col-sm-1,
	.post-comment .col-sm-1 {
		width: 13%;
	}

	.create-post-container .col-sm-10,
	.post-comment .col-sm-10 {
		width: 74%;
	}

	.post-box-header span.user-info {
		width: 85%;
	}

	.content-footer ul li i {
		width: 30px;
		height: 30px;
	}

	.last-comment .comment-text,
	.content-role,
	.content-time,
	span.counts {
		font-size: 13px;
	}

	.form-control {
		font-size: 0.8rem;
	}

	.post-container .post-body {
		.title {
			font-size: 15px;
		}

		.welcome-post h4 {
			font-size: 16px;
		}
	}

	.welcome-post .text-secondary {
		white-space: unset !important;
	}

	.p-box {
		padding-top: 0px !important;
		padding-right: 15px;
		padding-left: 15px;
	}

	.container.main-content,
	.col-md-12.post-col {
		padding: 0;
	}

	/*.main-content-inner{
            padding: 12px;
            padding-left: 4px;
            padding-top:0px;
        }*/

	.pb-like {
		width: 20px;
		height: 18px;
	}

	.create-post-container textarea {
		min-height: 50px;
	}

	.search-input {
		width: 85% !important;
		margin-left: 22px !important;
	}

	.bottom-nav-bar {
		position: fixed;
		z-index: 111;
		background: #fff;
		width: 97%;
		display: block;
		bottom: 0;
		transition: bottom 0.3s;

		li.nav-item {
			display: inline-block;
			padding-right: 5%;
			padding-left: 5%;
		}
	}

	.more-nav-span {
		width: 40px;
		display: block;
		text-align: center;
	}

	.nav-items li.nav-item .nav-link {
		border: 0 none !important;
		position: relative;
	}

	.network-name {
		font-size: 1.2rem;
	}

	.row-mg-fix.join-network {
		margin-left: -46px !important;
	}

	.pb-title {
		font-size: 16px !important;
	}

	.join-networks {
		padding-top: 12px;
	}

	.navbar-collapse .dropdown-item .col-sm-9 {
		width: 92% !important;
	}

	.point-user-detail {
		padding-left: 13px;
	}
}

.search-string {
	background-color: rgba(234, 68, 99, 0.48);
	border-radius: 3px;
	color: black;
}

.comments-container {
	.content-body {
		.url-attachment {
			margin-top: 10px;
			height: 70px;
			border: 0.5px solid rgb(188, 191, 198);
		}

		.image-container {
			float: left;
			width: 20%;

			/* height: 150px; */

			img {
				width: 100%;
				height: auto;
			}
		}

		.details-container {
			float: right;
			width: 80%;
		}
	}

	.url-attachment .details-container p {
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
		text-overflow: ellipsis;
	}
}

/* remember to define focus styles! */

:focus {
	outline: 0;
}

.report-options {
	.badge {
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		display: block;
		font-size: 1.2rem;

		/* padding: 5px 10px; */
		font-weight: 500;
		width: 90%;
		border-radius: 15px;
		text-align: center;
		border: 1px solid #ea4463;

		&.active {
			background-color: #ea4463 !important;
			color: #ffffff !important;
		}
	}

	.input-container {
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		display: block;
		width: 90%;
	}
}

.showFilterList {
	display: block !important;
}

.attachment-icons .pb-mension-mg {
	height: 21px !important;
	vertical-align: top;
}

.preview_img {
	.pb-file-mg {
		width: 30px;
		height: 35px;
	}

	.pb-audio-mg {
		width: 35px !important;
		height: 35px !important;
	}
}

/* .file-attachment .pb-icon{
      width: 50px !important;
      height: 60px !important;
  } */

.file-attachment {
	.pb-icon {
		width: 41px !important;
		height: 50px !important;
	}

	.pb-audio-mg {
		height: 47px !important;
	}

	a {
		padding: 2%;
		display: block;
		width: 100%;
		border: 1px solid rgb(188, 191, 198);
		font-size: 1.5rem;
		line-height: 3rem;
		border-radius: 5px;
	}
}

/* .file-attachment a {
      margin: 0px 40%;
      display: block;
      width: 20%;
      text-align: center;
  } */

[contenteditable='true']:empty:not(:focus):before {
	content: attr(data-ph);
	color: #818d96;
}

#chanel-image-remove {
	position: absolute;
	bottom: 7%;
	left: 37%;
	right: 37%;
	width: 25%;
}

.pb-text-link {
	color: #004eaf !important;
	text-decoration: underline !important;
	word-break: break-all;

	&:hover {
		color: #004d7b !important;
		font-weight: 200;
	}
}

/*Language Selector*/

.ds-lag-frame {
	/* float: right; */
	position: relative;
	width: 100px;
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
	border-radius: 15px;
	overflow: hidden;
}

.ds-lag-link {
	display: block;
	float: left;
	width: 50%;
	background: #eee;
	padding: 6px 10px;
	text-align: center;
	line-height: 1;
	font-size: 13px;
	color: #555;
	text-transform: uppercase;
}

.ds-lag-active {
	background: var(--primary-400);
	color: #fff;
}

.typing-msg {
	position: absolute;
	font-size: 14px;
	background: #cae9bf;
	padding: 0 12px;
	border-radius: 7px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	line-height: 1.24;
	letter-spacing: 1.01px;
}

.offline {
	height: 8px;
	width: 8px;
	background: orange;
	display: block;
	position: relative;
	border-radius: 10px;
	right: -20px;
	bottom: 27px;
}

.online {
	height: 8px;
	width: 8px;
	background: #1ef61e;
	display: block;
	position: relative;
	border-radius: 10px;
	right: -20px;
	bottom: 27px;
}

span.message.align-center-inner {
	padding: 0 !important;
	background-color: #f1ddcfe8 !important;
	color: #676161 !important;
	display: block !important;
	float: unset !important;
}

.align-center {
	margin: 0 auto;
	width: 80%;
	display: block;
	font-size: 13px;
	padding: 3px 2px;
	float: unset !important;
	text-align: center;
}

#auth-container .error-message-container {
	height: 15px;
}

.textHover:hover {
	color: red;
}

/**----Reported issues--*/

.Oval {
	width: 13px;
	height: 13px;
	border-radius: 50%;
}

.pb-box-mg {
	background: url(../assets/img/box-magenta.svg) 0 0 no-repeat;
}

.pb-chart-mg {
	background: url(../assets/img/chart-magenta.svg) 0 0 no-repeat;
}

.postCount {
	padding: 0;
	text-align: right;
	width: 15px;
	height: 20px;
	font-family: Inter;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #000000;
}

.icon-right {
	width: 18px;
	height: 14px;
	object-fit: contain;
}

.profile {
	position: relative;
	top: 6px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: #ff2d55;
	display: inline-block;
	line-height: 35px;
}

.profile_p1 {
	position: relative;
	top: 6px;
	width: 36px;
	height: 35px;
	border-radius: 50%;
	background-color: #ff2d55;
	display: inline-block;
	line-height: 35px;
}

.profile_p2 {
	position: relative;
	top: 6px;
	width: 36px;
	height: 35px;
	border-radius: 50%;
	background-color: #ff9500;
	display: inline-block;
	line-height: 35px;
}

.profile_p3 {
	position: relative;
	top: 6px;
	width: 36px;
	height: 35px;
	border-radius: 50%;
	background-color: #ffd300;
	display: inline-block;
	line-height: 35px;
}

.P1 {
	width: 13px;
	height: 17px;
	font-family: Inter;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}

.P2 {
	color: white;
	text-align: center;
	font-size: 16px;
}

.userName_issue {
	color: rgb(159, 159, 159);
	font-size: 15px;
}

.descriptionForIssue {
	font-size: 15px;
	line-height: 1rem;
	padding-bottom: 0px;
	display: inline;
	white-space: pre-wrap;
	font-family: 'Inter';
	word-wrap: break-word;
}

.currentLocModal {
	margin-bottom: 8px;
	color: var(--primary-color);
}

.btn-outline-secondary {
	border-color: var(--primary-color);
	color: var(--primary-color);

	&:hover {
		background-color: transparent;
		color: var(--primary-color);
		border-color: var(--primary-color);
	}
}

/*--Issue analytics page----*/

.Rectangle-Copy-5 {
	width: 509px;
	border-radius: 6px;
	border: solid 0.5px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	margin-top: 9px;
	margin-bottom: 9px;
	position: relative;
}

.issue-back {
	background: url(../assets/img/back.svg) 0 0 no-repeat;
	width: 11px;
	height: 20px;
	position: relative;
	right: 12px;
	bottom: 7px;
}

.issue-card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;

	&:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	}
}

.card-1 {
	background-color: rgb(250, 218, 226);
}

.card-2 {
	background-color: rgb(228, 223, 248);
}

.card-3,
.card-4 {
	background-color: rgb(220, 248, 231);
}

.analytics-arrow {
	background: url('../assets/img/chevronRight(1).svg') 0 0 no-repeat;
	background-size: contain !important;
	float: right;
	height: 15px;
}

.analytics-arrow-down {
	background: url('../assets/img/chevronRight.svg') 0 0 no-repeat;
	background-size: contain !important;
	float: right;
	height: 15px;
}

.list-analytics {
	position: relative;
	right: 32px;
}

.greyColor {
	color: #9f9f9f;
}

.issueAvatar {
	width: 45px;
	height: 45px;
	border-radius: 25px;
	float: left;
	margin-right: 15px;
}

.PeerBie_Logo-02-Copy {
	text-align: center;
	position: absolute;
	bottom: 15px;
	right: 13em;
}

.border-issue {
	width: 509px;
	height: 1px;
	object-fit: contain;
}

.todo-font {
	font-size: 22px;
	font-weight: 500;
}

.issue-counts {
	font-size: 15px;
	padding-left: 5px;
	margin-bottom: 5px;
}

.issue-icon {
	width: 26px;
	height: 24px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: bottom;
}

.issue-footer {
	padding-top: 12px;
	padding-bottom: 40px;
}

.issue-ul {
	display: inline;
}

/**--------------Notification Control---------------------------------------------*/

.pb-octagon {
	background: url(../assets/img/xOctagonMagenta.png) 0 0 no-repeat;
}

.notification-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
}

/* Create a custom checkbox */

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #f3f0f0;
	border-radius: 5px;
}

/* On mouse-over, add a grey background color */

.notification-container {
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark {
		background-color: var(--success-color);
	}
}

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.signup-font {
	font-size: 18px;
}

/* Show the checkmark when checked */

.notification-container {
	input:checked ~ .checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

/* Style the checkmark/indicator */

.tick-icon {
	background: url(../assets/img/active.png) 0 0 no-repeat;
	width: 16px;
	height: 16px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: middle;
}

.pb-tick {
	background: url(../assets/img/shapeTick.png) 0 0 no-repeat;
	width: 18px;
	height: 18px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: middle;
}

.phone-img {
	background: url(../assets/img/smartphone-call.png) 0 0 no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
}

.website-img {
	background: url(../assets/img/world-wide-web.png) 0 0 no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
}

.loc-img {
	background: url(../assets/img/group-3.svg) 0 0 no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
}

.fourSquare-img {
	background: url(../assets/img/group-6-copy.svg) 0 0 no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
}

.googleMap-img {
	background: url(../assets/img/group-6.svg) 0 0 no-repeat;
	background-size: cover;
	background-repeat: no-repeat;
	width: 45px;
	height: 45px;
}

.checkin-btn {
	color: white;
	background-color: #ea4b62;
	border-radius: 7px;
	font-size: 16px;
	font-weight: 500;
}

/*---------------------*/

.rating {
	float: left;

	&:not(:checked) {
		> {
			input {
				position: absolute;
				top: -9999px;
				clip: rect(0, 0, 0, 0);
			}

			label {
				float: right;
				width: 1em;

				/* padding:0 .1em; */
				overflow: hidden;
				white-space: nowrap;
				cursor: pointer;
				font-size: 250%;

				/* line-height:1.2; */
				color: #ddd;

				&:before {
					content: '★ ';
				}
			}
		}

		> label:hover {
			color: #ea4b62;

			~ label {
				color: #ea4b62;
			}
		}
	}

	> input:checked ~ label {
		color: #ea4b62;
	}

	> {
		input:checked {
			+ label:hover,
			~ label:hover {
				color: #ea4b62;

				~ label {
					color: #ea4b62;
				}
			}
		}

		label:hover ~ input:checked ~ label {
			color: #ea4b62;
		}
	}
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t 
      follow these rules. Every browser that supports :checked also supports :not(), so
      it doesn’t make the test unnecessarily selective */

.pb-modal {
	.checkin-header {
		padding: 0.2em 0.2rem !important;
		border-bottom: 1px solid #e9ecef;
	}

	.checkin-content {
		margin-top: 1vh;
	}

	.modal-content {
		background-color: var(--primary-bg-color);
		color: var(--color-dark);
	}
}

.pb-checkin .user-name {
	background-color: var(--primary-color);
	width: 35px;
	height: 35px;
	font-size: 1rem;
	border-radius: 25px;
	text-align: center;
	padding: 7px;
	color: white !important;
	background-size: cover;
	text-transform: uppercase;
}

.loc-action-container {
	width: 300px;
	height: 160px;
	border-radius: 6px;
	background-color: #ffffff;
}

.mention-box {
	padding: 0;
	overflow-x: hidden;
	z-index: 10;
}

.loc-create {
	position: fixed;
	z-index: 1022;
	bottom: 12%;
	right: 12%;
}

.loc-action-img1 {
	background: url(../assets/img/group-3.svg) 0 0 no-repeat;
	width: 45px;
	height: 45px;
}

.loc-action-img2 {
	background: url(../assets/img/group-7.svg) 0 0 no-repeat;
	width: 45px;
	height: 45px;
}

/*--------- For getting conversation line in comments --------*/

.conversation-line {
	position: relative;
}

.user-container .conversation-line:before {
	left: 6% !important;
	margin-left: 0 !important;
}

.conversation-line:before {
	content: '';
	position: absolute;
	bottom: 0;
	width: 2px;
	background: #a9a9a9;
	left: 3%;
	height: calc(100% - 36px);
}

.last-comment.conversation-line:before {
	background: var(--border-color-dark) !important;
	left: 18px;
}

/**For fixing comment line in profile page posts*/

.profile-line {
	position: relative;
}

.user-container .profile-line:before {
	left: 3% !important;
	margin-left: 0 !important;
}

.profile-line:before {
	content: '';
	position: absolute;
	bottom: 0;
	width: 2px;
	background: #a9a9a9;
	left: 3%;
	height: calc(100% - 36px);
}

.dropdown-action:hover,
.link-text:hover {
	color: white;
}

.drop-text {
	position: relative;
	left: 24px;
	color: white;
}

/*-----For inapp notification time -----*/

.inapp-notification-time {
	font-size: 12px;
	color: black;
	font-weight: normal;
}

.ngb-tp-chevron {
	font-weight: 200 !important;
	color: #eb4a63 !important;
}

.meeting-post-details {
	padding-top: 200px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: 'Inter';
	font-size: 19.8px;
	color: white;
}

.display-MembersList {
	overflow-x: hidden;
	overflow-y: auto;
	height: 300px;
}

.save-btn {
	color: var(--danger-color);

	&:hover {
		color: var(--primary-bg-color);
		background-color: var(--danger-color-hover);
	}
}

/* ------ Toast message for reminder actions ---------*/

.toast-body {
	width: 300px;
	background-color: #ffff;
	position: relative;
	min-height: 60px;
	max-height: 300px;
	border-radius: 5px;

	/* margin-top: 20px; */
	color: #ea4b62;
	font-weight: 500;
	transition: all 1s;
	box-shadow: 0px 3px 5px 0px rgb(0, 0, 0, 0.7);
}

.toast-container {
	position: fixed;
	z-index: 900;
	right: 30px;
	padding-top: 30px;
}

.close-toast {
	float: right;
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 1;
	color: black;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}

.fade-in {
	animation: fadeIn ease 2s;
	-webkit-animation: fadeIn ease 2s;
	-moz-animation: fadeIn ease 2s;
	-o-animation: fadeIn ease 2s;
	-ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/**-----------------------------------------------------------*/

/*--Toast containe for home page*/

.toast-container-home {
	position: absolute;
	top: 5px;
	right: 20px;
	z-index: 1200;
}

.number-style {
	font-family: 'Times New Roman', Times, serif;
	font-style: normal;
	font-weight: 500;
}

.bold {
	font-weight: 500;
}

.product-title {
	position: relative;
	right: 10px;
}

.delete-img {
	background: url(../assets/img/trash-2-2.svg) 0 0 no-repeat;
	background-repeat: no-repeat;
}

.issue-dropdown {
	position: relative;
	left: 5px;
	margin-top: -10px;
}

.new-nav {
	margin-top: 11px;
}

/**----- Changes in home pages ----*/

.sticky-nav {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1023;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.scroll-sidebar {
	position: fixed;

	/* width: 21%; */
	height: 90%;
}

.sidebar-items-scroll {
	overflow: auto;
	height: 400px;

	&:hover {
		overflow: auto;
	}
}

/* @media (min-width: 1200px) and (max-width: 1300px) {
      .sidebar-items-scroll {
          overflow: auto;
          height: 400px;
      }
      
  }
   
  
  @media (min-width: 1300px) {
      .sidebar-items-scroll {
          overflow: auto;
          height: 100%;
      }
      .scroll-sidebar {
          height: auto;
      }
  }  */

/* current changes in 29-04 6pm */

.filter-fixed {
	position: fixed;
	width: 325px;
	max-height: 500px;
}

.filter-items-scroll {
	overflow: hidden;
	max-height: 400px;

	&:hover {
		overflow: auto;
	}
}

.noti-filter-fixed {
	position: fixed;
	width: 325px;
	overflow: hidden;
	max-height: 550px;
}

.noti-filter-items {
	overflow: hidden;
	max-height: 500px;

	&:hover {
		overflow: auto;
	}
}

.members-filter-fixed {
	position: fixed;
	max-height: 450px;
	width: 325px;
}

.members-filter-items {
	overflow: hidden;
	max-height: 400px;

	&:hover {
		overflow: auto;
	}

	a {
		color: var(--color-dark);
	}
}

.channel-filter-fixed {
	position: fixed;
	width: 325px;
	max-height: 600px;
}

.channel-filter-items {
	overflow: hidden;
	max-height: 300px;

	&:hover {
		overflow: auto;
	}
}

.events-filter-fixed {
	position: fixed;
	width: 325px;
	max-height: 550px;
}

.events-filter-items {
	overflow: hidden;
	max-height: 450px;

	&:hover {
		overflow: auto;
	}
}

.buysell-filter-fixed {
	position: fixed;
	width: 325px;
	max-height: 550px;
}

.buysell-filter-items {
	overflow: hidden;
	max-height: 480px;

	&:hover {
		overflow: auto;
	}
}

.pb-txt {
	position: absolute;
	z-index: 90;
	top: 0;
	left: 0;
}

::-webkit-scrollbar {
	/* // Width of vertical scroll bar */
	width: 7px;

	/* // Height of horizontal scroll bar */
	height: 8px;
}

::-webkit-scrollbar-thumb {
	border-radius: 9px;
	background: var(--scroll-bar-thumb);
}

.meeting-overlay {
	position: absolute;
	top: 72%;

	/* -ms-transform: translate(-50%, -50%); */
	color: white;
	font-size: 16px;
	border: none;
	cursor: pointer;
	width: 100%;
	left: 12px;
}

.btn-meeting {
	color: white;
	border: 2px solid white;
	padding: 2px 20px !important;
	font-size: 17px;
	margin-bottom: 5px;

	&:hover {
		color: white;
		font-weight: 500;
		border: 2px solid #00efac;
		background-color: #00efac;
	}
}

.meeting-starts {
	position: relative;
	top: 2px;
}

/*-------Please wait progress bar for zoom app auth--------------*/

.confirm-box {
	top: 100px;
	width: 280px;
	position: relative;
	margin: 30px auto;
	color: rgba(0, 0, 0, 0.7);
	font-family: Helvetica;
	font-size: 15px;
	font-weight: 500;
	background: #ddd;
	border-radius: 0.4em;
	border: 1px solid rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

	h3 {
		text-align: center;
		padding: 10px 30px;
		line-height: 1.2em;
		border-radius: 0.4em 0.4em 0 0;
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 30px 30px rgba(255, 255, 255, 0.2);
		margin-bottom: 15px;
		background: rgb(232, 233, 233);
	}

	a {
		display: block;
		position: relative;
		padding: 7px 40px;
		width: 130px;
		margin: 0 auto;
		margin-bottom: 15px;
		text-decoration: none;
		text-align: center;
		font-size: 13px;
		border: 1px solid rgba(0, 0, 0, 0.7);
		border-radius: 0.2em;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 20px 20px rgba(255, 255, 255, 0.2), 1px 2px 1px rgba(0, 0, 0, 0.2), 0 0 2px 8px rgba(50, 50, 50, 0.1);
		transition: all 0.3s ease-out;
		background: #d02211;
		z-index: 100;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.6);
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

#progress-bar {
	width: 90%;
	margin: 0 auto;
	margin-bottom: 15px;
	height: 28px;
	border: 1px solid rgba(0, 0, 0, 0.8);
	background: #232323;
	border-radius: 1em;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

#inner-pb {
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.8);
	width: 0;
	height: 22px;
	margin-left: 5px;
	margin-top: 2px;
	background: repeating-linear-gradient(-45deg, #0077cc, #0077cc 20px, #0099ff 20px, #0099ff 40px);
	border-radius: 0.8em;
	animation: roll 5s linear infinite;
	box-shadow: inset 0 10px 10px rgba(255, 255, 255, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -5px 3px rgba(0, 0, 0, 0.2), 0 3px 2px rgba(0, 0, 0, 0.3);
}

@keyframes roll {
	0% {
		width: 0;
	}

	100% {
		width: 95%;
	}
}

@keyframes roll {
	0% {
		width: 0;
	}

	100% {
		width: 95%;
	}
}

.priority-network {
	position: relative;
	top: 6px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: white;
	display: inline-block;
	line-height: 35px;

	/* border-color: red; */
	border-style: solid;
	border-color: #ec6488;
}

.priority-text {
	color: #ec6488;
	text-align: center;
	font-size: 17px;
}

.zoom {
	transition: transform 0.2s;
	width: 200px;
	height: 200px;
	margin: 0 auto;

	&:hover {
		-ms-transform: scale(1.5);

		/* IE 9 */
		-webkit-transform: scale(1.5);

		/* Safari 3-8 */
		transform: scale(1.5);
	}
}

.assignee-container {
	min-height: 50px;
	margin-bottom: 0px;

	.avatar .user-pic {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		position: relative;
	}

	.user-name {
		font-size: 13px;
		font-family: 'Inter';
	}

	.role {
		font-size: 12px;
		font-family: 'Inter';
		color: rgb(159, 159, 159);
		text-transform: lowercase;
	}

	.department {
		margin-top: 10px;
		font-size: 12px;
		font-family: 'Inter';
		color: rgb(159, 159, 159);
	}

	.user-detail {
		margin-bottom: 0px;
	}
}

.pb-privacy .assignee-name {
	font-size: 1.7rem;
	padding: 15px;
	color: black;
	background-size: cover;

	/* text-transform: uppercase; */
}

.shadow-box {
	box-shadow: 0 1px 4px rgba(72, 66, 66, 0.65), 0 0 40px rgba(236, 220, 220, 0.1) inset;
	border-radius: 5px;
}

.drop-curved {
	font-size: 14px;
	border-radius: 8px;

	/* box-shadow: rgba(0, 0, 0, 0.48) 10px 16px 18px -12px; */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.i-box {
	margin-top: 15px;
	margin-bottom: 10px;
}

.item-modal {
	padding: 1rem;
	max-height: 70vh;
	overflow: auto;
}

.editTodo-body {
	max-height: 80vh;
}

.collection-preview-img {
	background-size: contain;
	height: 98px;
	border-radius: 8px;
	box-shadow: 7px 6px 9px -4px rgba(138, 131, 131, 0.75);
}

/*----Zoom Switch starts----*/

.switch-zoom {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 32px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

.slider-zoom {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e6e6e6;
	-webkit-transition: 0.4s;
	transition: 0.4s;

	&:before {
		position: absolute;
		content: '';
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
}

input {
	background-color: var(--input-backgorund);
	color: var(--color-dark);

	&:checked + .slider-zoom {
		background-color: var(--success-color);
	}

	&:focus + .slider-zoom {
		box-shadow: 0 0 1px var(--success-color);
	}

	&:checked + .slider-zoom:before {
		transform: translateX(26px);
		height: 25px;
	}
}

/* Rounded sliders  */

.slider-zoom.round {
	border-radius: 34px;

	&:before {
		border-radius: 50%;
	}
}

/*----Zoom ends-----------------*/

.todoPost {
	background-image: url(../assets/img/todoPost.jpg);
	background-repeat: no-repeat;
	width: auto;
	height: 300px;
	border-radius: 5px;
	overflow: hidden;

	/* background-size: cover; */
	/* background-position: center; */
	background-repeat: no-repeat;
	margin-bottom: 0.5rem;
}

.todo-icons {
	width: 38px;
	height: 32px;
	filter: contrast(100);
}

.spinning {
	position: fixed;
	top: 110px;
	left: 0;
	bottom: 0;
	right: 0;
}

.user-mention-pic {
	width: 31px;
	height: 29px;
	border-radius: 12px;
}

.title-suggestion-dropdown {
	max-height: 200px;
	overflow: auto;
	position: absolute;
	background-color: #fffdfd;
	max-width: 400px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 99999;

	/* transition: visibility 0s, opacity 0.5s linear; */
	border-radius: 9px;
}

.question-post-img {
	background: url(../assets/img/quespic.png) 0 0 no-repeat;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;
	height: 120px;
	width: 100%;
	background-size: cover;
	border-radius: 10px;
}

.close-doc {
	position: relative;
	bottom: 71px;
	right: 8px;
}

/**************************Purhcase Plans Classess****************************************************/

.purchase-title {
	font-family: 'Inter';
	font-size: 1.5rem;
	margin-top: 23px;
	text-align: center;
	color: var(--primary-color);
}

.purchase-para {
	font-size: 19px;
	margin-top: 10px;
	text-align: center;
	color: #807376;
}

.purchase-card {
	width: 290px;
	border-radius: 6px;

	/* height: 600px; */
	cursor: pointer;
	height: 460px;
	box-shadow: 0 12px 12px -2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pc-card-body {
	text-align: center;
	padding-top: 5px;
}

.card {
	border: 0 none !important;
}

.card-title {
	text-align: center;
}

.card-block {
	padding: 16px;
}

.p-pc-bx .cursor-pointer {
	position: relative;
}

.purhcase-btn {
	border-radius: 20px;
	background-color: #ea4b62;
	color: #ffff;
	align-items: center;
	justify-content: center;
	display: flex;
	transition: all 0.3s ease 0s;
	font-size: 18px;

	&:hover {
		background-color: #a53c4b;
		color: #fff;
	}
}

.plans-text {
	padding-top: 35px;
	padding-bottom: 20px;
	text-align: center;
}

.pb-purhcase-btn {
	border-radius: 12px;
	background-color: #ea4b62;
	color: white;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	display: flex;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;

	&:hover {
		/* background-color: #2EE59D; */
		/* box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4); */
		color: #fff;
		transform: translateY(-7px);
	}
}

.pb-update-btn {
	border-radius: 48px;
	background-color: #ea4b62;
	color: white;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	display: flex;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
}

.pb-collec-btn {
	border-radius: 9px;

	/* background-color: #ea4b62;
        color: white; */
	height: 29px;
	align-items: center;
	justify-content: center;
	display: flex;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
}

.pb-update-btn:hover {
	color: #fff;
	transform: translateY(-7px);
}

.pc-secondary {
	background-color: #efeeee;
	color: black;
	font-weight: 500;
	padding: 4px;
	margin-bottom: 15px;
}

.pc-card1 {
	padding: 11px;
	border-radius: 10px;
	box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pc-card-2 {
	padding: 11px;
	border-radius: 10px;
	height: 100%;
	box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pc-br-bx {
	box-shadow: -2px 4px 5px -1px rgba(0, 0, 0, 0.75);
	border-radius: 10px;
	background: #ffffff;
	padding: 10px;
}

.pc-box {
	margin-top: 10px;
	padding: 12px 6px 6px 3px;
	overflow-x: hidden;
	width: inherit;
}

.premium-title {
	font-weight: 500;
	font-size: 18px;
	color: #ea4b62;
}

.pb-premium-card {
	padding: 14px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	float: none;
	margin: 0 auto;
	border-radius: 10px;
}

.pc-title {
	text-align: center;
	font-weight: 500;
	font-size: 24px;
	color: #ea4b62;
}

.border-white {
	color: white;
	border-color: #ffffff !important;
	border-radius: 40px;
}

.pc-input {
	border: none;
	border-bottom: 1px solid #ea4b62;
	outline: none;
	border-radius: none;
	border-radius: 0px;

	&:focus {
		outline: none;
		box-shadow: none !important;
		border-bottom: 1px solid #ea4b62;
	}
}

.pc-input-2 {
	border-radius: 30px;
	border: 1px solid #ea4b62;

	&:focus {
		outline: none;
		box-shadow: none !important;
		border: 1px solid #ea4b62;
	}
}

.pc-sub-title {
	font-size: 19px;
	text-align: center;
	color: black;
	margin-bottom: 40px;
}

.pc-amount {
	font-size: 310%;
	color: #eb4b60;
}

.pc-pink {
	font-family: 'Inter';
	font-size: 18px;
	color: #ea1f61;
}

.pc-annual-card {
	position: relative;

	/* bottom: 48px; */
	padding: 8px;
	height: 100%;
	border-radius: 10px;
	box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pc-plan-card {
	padding: 12px;

	/* height: 202px; */
	font-weight: 500;
	border-radius: 10px;
	box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pc-arrow-down {
	background: url(../assets/img/chevronRightCopy.png) 0 0 no-repeat;
	height: 14pt;
	width: 14pt;
	background-size: contain !important;
	float: right;
	margin-top: 6px;
}

.pc-select {
	border-radius: 30px;
	border: 1px solid #ea4b62;
}

.pc-bold {
	font-weight: 500;
	font-size: 22px;
}

.pc-upgarde-btn {
	position: absolute;
	top: 79%;
	left: 31%;
}

.pc-credit-cards {
	background: url(../assets/img/credit-card-icons.png) 0 0 no-repeat;
	width: 20px !important;
	height: 20px !important;
}

.pc-user-mg {
	background: url(../assets/img/userMagenta.svg) 0 0 no-repeat;
	padding-left: 36px;
}

.pc-mail-mg {
	background: url(../assets/img/mailMagenta.svg) 0 0 no-repeat;
	padding-left: 36px;
}

.pc-calendar {
	background: url(../assets/img/calendarMagenta.png) 0 0 no-repeat;
	padding-left: 30px;
}

.pc-date {
	background: url(../assets/img/pc-date.png) 0 0 no-repeat;
	padding-left: 32px;
	width: 100%;
	background-size: contain;
	height: 23px;
}

.pc-calendar-view {
	background: url(../assets/img/pc-date.png) 0 0 no-repeat;
	background-size: contain;
}

.pc-lock {
	background: url(../assets/img/pc-lock.png) 0 0 no-repeat;
	padding-left: 32px;
	width: 100%;
	background-size: contain;
	height: 25px;
}

.pc-phone {
	background: url(../assets/img/phoneicon-1.png) 0 0 no-repeat;
	padding-left: 32px;
	width: 100%;
	background-size: contain;
	height: 25px;
}

.pc-warning-modal {
	position: fixed;
	z-index: 900;
	right: 30px;
	padding-top: 60px;
	top: 0;
}

.btn-fixed {
	min-width: 80px;
	max-width: 80px;
}

.pc-btn-fixed {
	min-width: 119px;
	max-width: 119px;
}

.col-centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.pc-status {
	text-align: center;
	font-weight: 500;
	font-size: 26px;
	color: #58bda9;
}

.pc-alert {
	color: #404966;
	font-size: 17px;
	font-weight: normal;
}

.input-invalid {
	border: 1px solid red;
}

.pc-red {
	color: red;
}

.pc-plan {
	font-weight: 500;
}

.pc-spinner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.pb-todo-filters {
	height: 46px;
}

.pc-green {
	color: rgb(68, 169, 71) !important;
}

.pb-collection-success {
	background: url(../assets/img/blue.png) 0 0 no-repeat;
	height: 37px;
	width: 46px;
	background-size: contain !important;
	margin: 0 auto;
}

.grey-hover:hover,
.pb-grey {
	background-color: #f5f5f5;
	border-radius: 8px;
}

.active-members {
	background: url(../assets/img/b5.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.total-members {
	background: url(../assets/img/b6.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.open-todo {
	background: url(../assets/img/b2.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.closed-todo {
	background: url(../assets/img/b3.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.cal-todo {
	background: url(../assets/img/b1.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.tasks-todo {
	background: url(../assets/img/b4.png) 0 0 no-repeat;
	height: 26px;
	background-size: contain !important;
}

.todo-texts {
	color: white;
	padding-left: 31px;
	padding-top: 5px;
	font-weight: 500;
}

.sigin-checkbox {
	width: 19px;
	height: 20px;
	vertical-align: middle;
}

.country-box {
	background-color: white;
	padding: 6px 14px;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.task-font {
	/* height: 20px; */
	font-size: 17px;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000000;
}

.em-l {
	font-size: 17px;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #989ea7;
}

.ngb-tp .ngb-tp-meridian .btn {
	color: red;
	border: 1px solid red;

	&:hover {
		background-color: red;
		color: white;
	}
}

.pc-underline,
.pc-underline-2 {
	text-align: center;
	font-size: 16px;
	color: #ea4b62;
}

.pc-underline:hover {
	text-decoration: underline;
	cursor: pointer;
}

.pc-text-one {
	font-size: 17px;
	text-align: center;
	color: #4c545f;
}

.pc-white-one {
	font-size: 17px;
	text-align: center;
	color: white;
}

.pc-heading {
	color: #273240;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 32px;
	font-weight: 400;
	line-height: 40px;
	text-align: center;
}

.pc-white-heading {
	color: white;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 32px;
	font-weight: 400;
	line-height: 40px;
	text-align: center;
}

.pc-highlight {
	color: white;
	background: linear-gradient(to right, rgb(203, 53, 107), rgb(189, 63, 50));
}

.pc-labels {
	font-size: 15px;
	color: #6f7782;
}

.pc-essentials {
	padding: 67px 0px;
	text-align: center;
}

.change-plan-dialog {
	width: 600px;
}

/* @media (min-width: 992px) {
      .modal-dialog {
        max-width: 80%;
      }
    } */

.change-plan-title {
	font-size: 24px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.change-plan-font {
	font-size: 20px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.pc-apple-font {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 15px;
}

.pc-select-box {
	border-radius: 12px;
	border: 1px solid #9b9696;
}

.pc-card-font {
	font-size: 17px;
	color: black;
}

.pc-select-box-2 {
	color: white;
	background-color: #ea4b62;
	border-radius: 12px;
	border: 2px solid #ffffff;
}

.cal-day-selected {
	background-color: pink !important;
}

.todo-img {
	height: 20px;
	padding: 20px;
}

.bg-peach {
	background-color: #f3c9c9 !important;
	color: #ff1010 !important;
	font-size: 1.4em;
}

.bg-pink {
	background-color: pink !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.gray-dot {
	background: url(../assets/img/greyDot.png) 0 0 no-repeat;
	background-size: 13px 13px;
	background-position: 52% 85%;
}

.red-dot {
	background: url(../assets/img/Basic_red_dot.png) 0 0 no-repeat;
	background-position: 52% 85%;
	background-size: 13px 13px;
}

.lobster-red {
	background: url(../assets/img/Basic_red_dot.png) 0 0 no-repeat;
	background-position: 52% 85%;
	background-size: 13px 13px;
	background-color: #f3c9c9 !important;
	color: #ff1010 !important;
	font-size: 1.4em;
}

.lobster-gray {
	background: url(../assets/img/greyDot.png) 0 0 no-repeat;
	background-position: 52% 85%;
	background-size: 13px 13px;
	background-color: #f3c9c9 !important;
	color: #ff1010 !important;
	font-size: 1.4em;
}

.pb-visa {
	background: url(../assets/img/visa.png) 0 0 no-repeat;
	width: 30px !important;
	height: 21px !important;
	background-position: center;
}

.pb-mastercard {
	background: url(../assets/img/mastercard.png) 0 0 no-repeat;
	width: 30px !important;
	height: 21px !important;
	background-position: center;
}

.pb-american-express {
	background: url(../assets/img/american-express.png) 0 0 no-repeat;
	width: 30px !important;
	height: 21px !important;
	background-position: center;
}

.no-border {
	border: 0;
}

.signup-box {
	border-radius: 13px;
	box-shadow: 0 3px 6px rgb(247 235 235 / 16%), 0 3px 6px rgba(0, 0, 0, 0.23);
	border-color: white;
}

.sc-2 {
	border-radius: 7px;
	box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
}

.point-number {
	font-size: 22px;
	color: var(--success-color);
	font-weight: 500;
}

.point-points {
	font-weight: 500;
	color: #e8474c;
	font-size: 15px;
}

.point-user-role {
	font-size: 13px;
	font-family: 'Inter';
	color: #e8474c;
}

.border-red {
	border-color: red;
}

.border-orange {
	border-color: orange;
}

.border-yellow {
	border-color: yellow;
}

.highPriority {
	color: #ea4b62;
}

.medPriority {
	color: #ef6f07;
}

.lowPriority {
	color: #ffb22d;
}

.pb-count-circle {
	position: relative;
	top: 6px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: white;
	display: inline-block;
	line-height: 35px;

	/* border-color: red; */
	border-style: solid;
	border-color: #ec6488;
}

.todos-count {
	color: #ec6488;
	text-align: center;
	font-size: 17px;
}

.pb-todo-count {
	position: relative;
	bottom: 13px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	color: white;
	display: inline-block;
	line-height: 37px;
	text-align: center;
	font-size: 20px;
	height: 34px;
	width: 34px;
}

.circle-red {
	background-color: #ea4b62;
}

.circle-orange {
	background-color: #fa852b;
}

.circle-blue {
	background-color: var(--success-color);
}

.p-red {
	color: var(--primary-500);
}

.p-orange {
	color: #fa852b;
}

.p-blue {
	color: var(--success-color);
}

.pb-bar {
	height: 69px;
}

.list-f1 {
	font-size: 15px;
	color: black;
}

.todoList-img {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 1px solid white;
	box-shadow: 0 0 0 2px #fff, 0 0 0 5px #ea4b62;
}

.todo-radio-on {
	background: url(../assets/img/blue.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
}

/* .todo-radio-off {
      background: url(../assets/img/off.svg) 0 0 no-repeat;
      height: 20pt;
      width: 20pt;
  } */

.todo-off {
	background: url(../assets/img/off.svg) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
}

/* .todo-off {
      background: url(../assets/img/off3.png) 0 0 no-repeat;
      height: 22pt;
      width: 21pt;
      cursor: pointer;
      display: inline-block;
      vertical-align: bottom;
      background-size: cover;
  } */

.todo-on {
	background: url(../assets/img/todo-on.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
	background-size: contain;
}

.todo-more {
	// height: 20pt;
	// width: 20pt;
	// display: inline-block;
	cursor: pointer;
	// position: absolute;
	// right: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.more-icon {
	background: url(../assets/img/todo-more.png) 0 0 no-repeat;
}

.todo-default {
	background: url(../assets/img/check2.png) 0 0 no-repeat;
	background: url(../assets/img/open.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
	background-size: contain;
}

.todo-progress {
	background: url(../assets/img/check3.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
	background-size: contain;
}

.open {
	background: url(../assets/img/open.png) 0 0 no-repeat;
}

.in-progress {
	background: url(../assets/img/progress3.png) 0 0 no-repeat;
}

.closed {
	background: url(../assets/img/closed3.png) 0 0 no-repeat;
}

.paused {
	background: url(../assets/img/paused3.png) 0 0 no-repeat;
}

.todo-round {
	height: 23pt;
	width: 23pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
	background-size: 29px;
}

.start {
	background: url(../assets/img/play2x.png) 0 0 no-repeat;
	width: 25px !important;
	height: 26px !important;
}

.pause {
	background: url(../assets/img/paused2x.png) 0 0 no-repeat;
	width: 25px !important;
	height: 25px !important;
}

.stop {
	background: url(../assets/img/closed2x.png) 0 0 no-repeat;
	width: 25px !important;
	height: 25px !important;
}

.off {
	background: url(../assets/img/off.png) 0 0 no-repeat;
	display: inline-block;
	vertical-align: bottom;
	background-size: contain;
	height: 11pt;
	width: 11pt;
	margin-right: 6px;
	margin-bottom: 3px;
}

.md-drppicker td.off {
	background-color: #fff;
	border-color: transparent;
	color: #999;
	width: auto;
	margin: auto;
	vertical-align: unset;
	display: revert;
	background: none;

	&.end-date,
	&.in-range,
	&.start-date {
		background-color: #fff;
		border-color: transparent;
		color: #999;
		width: auto;
		margin: auto;
		vertical-align: unset;
		display: revert;
		background: none;
	}
}

.todo-user {
	width: 31px;
	height: 31px;
	background-size: cover;
	border-radius: 100px;
	margin-left: 5px;
	float: right;
}

.todo-br {
	padding-bottom: 1.5rem;
	border-bottom: 1px solid var(--border-color-dark) !important;
}

.issue-up {
	background: url(../assets/img/issueTop.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	right: 4px;
	top: 4px;
}

.issue-down {
	background: url(../assets/img/issueDown.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	right: 4px;
	top: 4px;
}

.issue-up1 {
	background: url(../assets/img/issueTop.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
}

.issue-down1 {
	background: url(../assets/img/issueDown.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
}

.b-20 {
	border-radius: 20px;
}

.post-user {
	height: 42px;
	width: 43px;
	border-radius: 50%;
}

.cp-br {
	border: 1px solid #3f3f3f;
	border-radius: 9px;
	padding: 2px 4px 2px 4px;
}

.cp-border {
	border: 1px solid var(--border-color-light);
	border-radius: 3px;
	padding: 4px 10px;
	width: fit-content;
	transition: all 0.3s ease-in-out;

	&:hover {
		border-color: var(--tw-color-gray-400);
	}
}

.member-attachment {
	border-radius: 11px;
	height: 70px;
	border: 1px solid rgb(188, 191, 198);
	padding: 14px;

	/* background-color: rgb(246 246 246); */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.col-xs-5-cols,
.col-sm-5-cols,
.col-md-5-cols,
.col-lg-5-cols {
	position: relative;
	min-height: 1px;
	padding-right: 9px;
}

.col-xs-5-cols {
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
}

@media (min-width: 768px) {
	.col-sm-5-cols {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 992px) {
	.col-md-5-cols {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (min-width: 1200px) {
	.col-lg-5-cols {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 19%;
	}
}

.shine-avatar {
	border-radius: 50%;
	width: 55px;
	height: 55px;
	display: inline-block;
}

.shine-block {
	width: 100%;
	height: 20px;
	display: inline-block;
	margin-bottom: 20px;
	margin-left: 9px;
}

.w-50 {
	width: 50%;
}

/* Welcome Tips */

.welcome-tips {
	background-color: var(--primary-bg-color);
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
	border-radius: 10px;
	padding: 30px 15px;
	margin: 0px -18px;
	margin-top: 20px;

	.tip-text {
		color: var(--primary-color);
		font-size: 18px;
		font-weight: 500;
		margin: 8px 0px;
	}

	.tip-btn {
		font-weight: 500;
		margin-top: 25px !important;
	}
}

.w-100 {
	width: 80% !important;
	height: auto;
}

.exit-btn:hover {
	filter: brightness(70%);
	-webkit-filter: brightness(70%);
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.shine {
	background: #f6f7f8;
	background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	display: inline-block;
	position: relative;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	animation-name: placeholderShimmer;
	animation-timing-function: linear;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: placeholderShimmer;
	-webkit-animation-timing-function: linear;
}

.i-container {
	position: relative;

	/* height: 100vh; */
	text-align: center;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #989ea7;
}

.i-centered {
	position: absolute;
	top: 83%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hv-100 {
	height: 60vh;
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.home-btn {
	background-color: #1ed0a9;
	border-radius: 12px;
	border-color: #1ed0a9;
	color: #ffffff;

	h5 {
		font-size: 23px;
	}

	&:hover {
		border-color: #1ed0a9;
		color: #ffffff;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
}

.b-text {
	font-size: 27px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.5;
	letter-spacing: normal;
	text-align: left;
	color: #1e1f20;
	padding-bottom: 9px;
}

.label-t {
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.24;
	letter-spacing: 2.03px;
	color: #1e1f20;
}

.code-block {
	color: #1e1f20;
	background-color: white;
	border: none;
	cursor: pointer;
}

.your-workplace {
	object-fit: contain;
	font-size: 25px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.33;
	letter-spacing: normal;
	text-align: center;
	color: #130f40;
	word-spacing: 2px;
}

.powered {
	font-size: 15.5px;
	color: #8f92a1;
	line-height: 1.43;

	b {
		font-size: 17px;
		color: var(--primary-color);
		cursor: pointer;
	}
}

.bold-black {
	color: black;
	font-size: 15.5px;
	font-weight: 500;
	cursor: pointer;
}

.border-gr {
	color: #8f92a1;
	border-right: solid 2px;
	height: 43px;
}

/***Sign up Checkbox Styles*****/

.checkbox {
	display: inline-flex;
	cursor: pointer;
	position: relative;

	> {
		span {
			color: #34495e;
			padding: 0.5rem 0.25rem;
		}

		input {
			height: 25px;
			width: 25px;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			appearance: none;
			border: 1px solid rgb(194 194 194);
			outline: none;
			transition-duration: 0.3s;
			background-color: #ffffff;
			cursor: pointer;

			&:checked {
				border: 1px solid rgb(194 194 194);
				background-color: #ffffff;

				+ span::before {
					content: '\2713';
					display: block;
					text-align: center;
					color: #7cdfc6;
					position: absolute;
					left: 5px;
					top: -3px;
					font-weight: bolder;
					font-size: 25px;
					font-weight: bolder;
				}
			}

			&:active {
				border: 1px solid rgb(194 194 194);
			}
		}
	}
}

/**********Green Loader***************/

.warrior {
	width: 10em;
	height: 10em;
	border-top: 1em solid #7cdfc6;
	border-right: 1em solid transparent;
	animation: spinner 0.4s linear infinite;
	border-radius: 50%;
	margin: auto;
	font-size: 7px;
}

.head {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin-left: 8.5em;
	margin-top: 0.5em;
	background-color: #d5fff7;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.warrior-sm {
	width: 4em;
	height: 4em;
	border-top: 3px solid #7cdfc6;
	border-right: 3px solid transparent;
	animation: spinner 0.4s linear infinite;
	border-radius: 50%;
	margin: auto;
	font-size: 7px;
}

/*****************************/

.otp-container {
	margin-left: 12.6%;
	margin-right: 12.6%;
	margin-top: 17%;
}

.otp-form {
	border-radius: 30px;
	background-color: #f8f8f8;
	padding: 50px 17px 50px 17px;
}

@media (max-width: 768px) {
	.otp-form {
		padding: 50px 10px 50px 14px;

		.otp-i {
			height: 41px;
			width: 31px;
			padding: 10px;
			display: inline-block;
		}
	}
}

@media (max-width: 320px) {
	.otp-form {
		padding: 50px 10px 50px 14px;

		.otp-i {
			height: 41px;
			width: 26px;
			padding: 8px;
			display: inline-block;
		}
	}
}

.otp-container .resend {
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
	text-align: center;
}

.otp-form:before {
	content: '';
	position: absolute;
	width: 2px;
	height: 412px;
	background: #d5d5d5;
	padding-right: -3px;
	padding-left: -20px;
	margin-left: -39px;
}

@media (max-width: 768px) {
	.otp-form::before {
		content: none;
	}
}

.otp-i {
	height: 50px;
	width: 50px;
	padding: 16px;
	display: inline-block;
}

/***Profile Img Uploader***/

.balloon {
	position: relative;
	left: 32%;

	.balloon-orange {
		position: relative;
		width: 20%;
		top: 0;
		left: 0;
	}

	.balloon-white {
		width: 65px;
		position: absolute;
		top: 8px;
		left: 27px;
	}

	.balloon-plus {
		width: 25px;
		position: absolute;
		top: 52px;
		left: 73px;
	}
}

/*  <div class="balloon">
              <img class="cursor-pointer balloon-orange" src="/assets/img/elips-1.png" />
              <img class="cursor-pointer balloon-white" src="/assets/img/white-user.png" />
              <img class="cursor-pointer balloon-plus" src="/assets/img/img-pl.png" />
            </div>*/

/*******************************/

.input-group-append .input-group-text {
	border: none;
	background-color: #ffffff;
}

.form-group .light {
	border: 1px solid #ced4da;
}

.has-search {
	.form-control {
		padding-left: 2.375rem;
		border-radius: 10px;
		border: solid 2px #313131;

		.search-white {
			border: 1px solid #ced4da;
			border-radius: 7px;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
			background-color: #ffffff;
		}
	}

	.form-control-feedback {
		position: absolute;
		z-index: 2;
		display: block;
		width: 2.375rem;
		height: 2.375rem;
		line-height: 2.375rem;
		text-align: center;
		pointer-events: none;
		color: #aaa;
		margin: 9px;
	}

	.form-control-clear {
		position: absolute;
		z-index: 2;
		display: block;
		width: 2.375rem;
		height: 2.375rem;
		color: #aaa;
		width: 27px !important;
		height: 21px !important;
		background-size: contain;
		margin: 9px;
		right: 40px;
		top: 25px;
		cursor: pointer;
	}
}

.cal-tick {
	background: url(../assets/img/active.png) 0 0 no-repeat;
	width: 19px;
	height: 19px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: middle;
}

.white {
	color: white;
	font-weight: 500;
}

.active-link {
	font-weight: 500;
	background-image: linear-gradient(to right top, #ea4b62, #eb4c63, #eb4e63, #ec4f64, #ec5065, #ec5068, #ec506a, #ec506d, #eb5072, #e95078, #e7507d, #e55182);
	color: white !important;

	.nav-link {
		color: white !important;
	}

	.pb-customers,
	.pb-h-customers {
		background: url(../assets/img/customer-w.png) 0 0 no-repeat;
	}

	.pb-issue-mg,
	.pb-h-issue {
		background: url(../assets/img/product-w.png) 0 0 no-repeat;
	}

	.pb-h-todolist {
		background: url(../assets/img/todolist-w.png) 0 0 no-repeat;
	}

	.pb-calendar {
		background: url(../assets/img/calendarWhite.svg) 0 0 no-repeat;
	}

	.pb-h-calendar {
		background: url(../assets/img/calender-w.png) 0 0 no-repeat;
	}

	.pb-collection {
		background: url(../assets/img/folderWhite.png) 0 0 no-repeat;
	}

	.unread-count {
		color: #ffffff;
	}
}

/**For file upload modal,[02-06-21]*/
@media (min-width: 576px) {
	.upload-dialog {
		max-width: 362px;
		margin: 1.75rem auto;
	}
}

/**User mentions/hashtag mentions dropdown*/

.mention-pic {
	width: 30px;
	height: 30px;
	border-radius: 25px;
}

.shine-mention {
	border-radius: 25px;
	width: 30pxpx;
	height: 30px;
	display: inline-block;
}

.shine-mention-name {
	width: 100%;
	height: 20px;
	display: inline-block;
	margin-left: 9px;
}

.mentions-drop {
	max-height: 200px;
	min-width: 240px;
	overflow: auto;
	overflow-x: hidden;
	position: absolute;
	background-color: #fffdfd;
	max-width: 400px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	// z-index: 10;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	z-index: 21;
}

.mention-item {
	border-bottom: 1px solid #e4e4e4;
	font-family: 'Inter';
	white-space: normal;
	height: 37px;
	cursor: pointer;
}

.dd-highlight-item {
	background-color: #e7e6e6;
	color: #fff;
}

/************/

.edit-img:hover {
	filter: brightness(70%);
	-webkit-filter: brightness(70%);
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.title-is {
	font-family: 'Inter';
	font-size: 16px;
	margin-bottom: 5px !important;
	padding-bottom: 0px;
}

.f-del {
	font-family: 'Inter';
	font-size: 16px;
	margin-bottom: 5px !important;
	padding-bottom: 0px;
	color: var(--primary-color);
}

@keyframes loader {
	0% {
		transform: rotate(-45deg);
	}

	50% {
		transform: rotate(-135deg);
	}

	100% {
		transform: rotate(-225deg);
	}
}

@keyframes span-1 {
	0% {
		transform: translate(0);
	}

	50% {
		transform: translate(-50px, 0);
		border-color: #ee4040;
		background-color: rgb(235, 79, 79);
	}

	100% {
		transform: translate(0);
	}
}

@keyframes span-2 {
	0% {
		transform: translate(0);
	}

	50% {
		transform: translate(50px, 0);
		border-color: #ee4040;
		background-color: rgb(235, 79, 79);
	}

	100% {
		transform: translate(0);
	}
}

@keyframes span-3 {
	0% {
		transform: translate(0);
	}

	50% {
		transform: translate(0, -50px);
		border-color: #ee4040;
		background-color: rgb(235, 79, 79);
	}

	100% {
		transform: translate(0);
	}
}

@keyframes span-4 {
	0% {
		transform: translate(0);
	}

	50% {
		transform: translate(0, 50px);
		border-color: #ee4040;
		background-color: rgb(235, 79, 79);
	}

	100% {
		transform: translate(0);
	}
}

.loader {
	width: 50px;
	height: 50px;
	position: relative;
	animation: loader 2s infinite ease-in-out;
	position: fixed;
	top: 41%;
	left: 47%;
	transform: translate(-50%, -50%);
	transform: -webkit-translate(-50%, -50%);
	transform: -moz-translate(-50%, -50%);
	transform: -ms-translate(-50%, -50%);

	span {
		width: 50px;
		height: 50px;
		position: absolute;
		left: 0;
		top: 0;
		border: 4px solid rgb(235, 79, 79);

		&:nth-child(1) {
			animation: span-1 2s ease-in-out infinite;
		}

		&:nth-child(2) {
			animation: span-2 2s ease-in-out infinite;
		}

		&:nth-child(3) {
			animation: span-3 2s ease-in-out infinite;
		}

		&:nth-child(4) {
			animation: span-4 2s ease-in-out infinite;
		}
	}
}

.alert-bl {
	font-family: 'Inter';
	font-size: 17px;
	margin-bottom: 5px !important;
	padding-bottom: 0px;
	color: white;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	background: rgba(0, 0, 0, 0.5);
}

.chat-user {
	width: 40px;
	height: 38px;
	border-radius: 25px;
	margin-right: 5px;
}

.title-l {
	font-size: 24px;
	font-family: 'Inter';
}

.post-btn {
	border-radius: 25px;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: 500;
}

/* .b-name {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.26;
      letter-spacing: 1.91px;
      color: #1e1f20;
  }  */

/***Calender ui*/

.cal-month-view {
	.cal-days {
		border: none !important;

		.cal-cell-row {
			border: none !important;
		}
	}

	.cal-day-cell:not(:last-child) {
		border: none !important;
		border: none !important;
	}

	.cal-cell-row {
		font-family: 'Inter';
	}

	.cal-day-cell {
		min-height: 49px !important;
	}
}

.due-cal .cal-month-view .cal-day-number {
	margin-bottom: 0px !important;
}

/**Points Team Member highlight*/

.team-member {
	border-radius: 50%;
	box-shadow: 0 0 0 3px #ffff, 0 0 0 5px #ea4b62;
}

.point-img {
	height: 42px;
	width: 41px;
	border-radius: 50%;
}

.team-circle {
	position: relative;
	bottom: 8px;
	right: -21px;
	text-align: center;
	height: 20px;
	width: 20px;
	font-size: 0.8rem;
	font-weight: 500;
	color: white;
	background-color: var(--primary-color);
	border-radius: 10px;
	box-shadow: 0px 0px 78px -6px rgba(0, 0, 0, 0.75);
	cursor: pointer;
	animation-name: reverse-rotation;
	animation-duration: 0.5s;
	transform: rotate(0deg);
}

.member-gr {
	background-color: rgb(241, 241, 242);
	border-radius: 14px;
}

.btn-ch {
	background-color: #ea4b62;
	color: white;

	&:hover {
		background-color: #ea4b62;
		color: white;
	}
}

.ch-img {
	width: 197px;
	height: 190px;
	background-size: cover;
	border-radius: 100px;
}

.upload-error {
	color: var(--primary-color);
	font-size: 16px;
	font-family: 'Inter';
}

.dot-red {
	height: 15px;
	width: 15px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
}

.dot-orange {
	height: 15px;
	width: 15px;
	background-color: #e78f0c;
	border-radius: 50%;
	display: inline-block;
}

.dot-blue {
	height: 15px;
	width: 15px;
	background-color: #48b5ed;
	border-radius: 50%;
	display: inline-block;
}

.dot-grey {
	height: 15px;
	width: 15px;
	background-color: #8e8e93;
	border-radius: 50%;
	display: inline-block;
}

.dot-green {
	height: 15px;
	width: 15px;
	background-color: #23b23b;
	border-radius: 50%;
	display: inline-block;
}

.dot-red {
	background-color: red;
}

.dot-orange {
	background-color: #e78f0c;
}

.dot-blue {
	background-color: #2ca5e3;
}

.dot-grey {
	background-color: #53535d;
}

.dot-green {
	background-color: #23b23b;
}

.dot-teal {
	background-color: #49cfcc;
}

/***Subtasks***/

.subtask-text:focus {
	outline: none;
	box-shadow: none !important;
}

.ic-2 {
	width: 29px !important;
	height: 29px !important;
	position: relative;
}

.ic-1 {
	width: 19px !important;
	height: 19px !important;
	position: absolute;
	top: 4px;
	left: 5px;
}

.sub-cell {
	padding: 3px 2px 0px 2px;

	&:hover {
		background-color: #f4f4f4;
		border-radius: 5px;
	}
}

.sub-br {
	-webkit-box-shadow: 0 4px 6px -6px #222;
	-moz-box-shadow: 0 4px 6px -6px #222;
	box-shadow: 0 4px 6px -6px #222;
}

.sub-user {
	width: 28px;
	height: 28px;
	background-size: cover;
	border-radius: 100px;

	/* margin-left: 6px; */
	float: right;
	border: 1px solid white;
}

.my-row li {
	width: auto;
	float: left;
	margin-right: -16px;
}

/* .sub-no {
      position: relative;
      top: -1px;
      left: 4px;
      width: 36px;
      height: 35px;
      border-radius: 50%;
      background-color: #dddddd;
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 4px;
      text-align: center;
      border: 1px solid white;
  } */

.sub-no {
	padding: 4px;
}

.ic-1 {
	background: url(../assets/img/ic_1.png) 0 0 no-repeat;
	height: 29px;
}

.ic-2 {
	background: url(../assets/img/ic_2.png) 0 0 no-repeat;
	height: 29px;
	width: 29px;
}

.cell-white {
	background-color: white;

	&::-webkit-input-placeholder {
		color: black;
		font-size: 18px;
	}
}

.cell-f {
	/* height: 20px; */
	font-size: 16px;
	font-weight: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #000000;
}

.isError {
	border: 1px solid red;
}

.err {
	color: rgb(240 61 16);
	font-size: 16px;
	font-family: 'Inter';
}

.pl-5 {
	padding-left: 3rem;
}

.pl-4 {
	padding-left: 2rem;
}

.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}

.pl-1,
.px-1 {
	padding-left: 0.25rem !important;
}

.pr-3,
.px-3 {
	padding-right: 1rem !important;
}

.sticky-sidebar {
	position: fixed;
	display: contents;
}

.new-message {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

/********************New updates****************/

.todo-due {
	background: url(../assets/img/todo-due2.png) 0 0 no-repeat;
	height: 17pt;
	width: 20pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
}

.sub-tasks {
	background: url(../assets/img/sub-tasks.png) 0 0 no-repeat;
	height: 16pt;
	width: 16pt;
	display: inline-block;
	vertical-align: bottom;
}

.prod-forward {
	background: url(../assets/img/prod-forward.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: 17px;

	/* right: 4px;
        top: 6px; */
}

.prod-down {
	background: url(../assets/img/prod-down.png) 0 0 no-repeat;
	height: 20pt;
	width: 20pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: 17px;

	/* right: 4px;
        top: 6px; */
}

.bar-0 {
	background: url(../assets/img/bar0.png) 0 0 no-repeat;
	height: 25pt;
	width: 24pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: contain;
}

.bar-1 {
	background: url(../assets/img/bar1.png) 0 0 no-repeat;
	height: 25pt;
	width: 24pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: contain;
}

.bar-2 {
	background: url(../assets/img/bar2.png) 0 0 no-repeat;
	height: 25pt;
	width: 24pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: contain;
}

.bar-3 {
	background: url(../assets/img/bar3.png) 0 0 no-repeat;
	height: 25pt;
	width: 24pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	background-size: contain;
}

/* .red-arr {
       background: url(../assets/img/red-arr.png) 0 0 no-repeat;
       height: 25pt;
       width: 24pt;
       display: inline-block;
       cursor: pointer;
       position: absolute;
       left: 0;
       top: 3px;
  }
  .orange-arr {
      background: url(../assets/img/orange-arr.png) 0 0 no-repeat;
      height: 25pt;
      width: 24pt;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  }
  .blue-arr {
      background: url(../assets/img/blue-arr.png) 0 0 no-repeat;
      height: 25pt;
      width: 24pt;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  }
  .black-arr {
      background: url(../assets/img/black-arr.png) 0 0 no-repeat;
      height: 25pt;
      width: 24pt;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  }
  .green-arr {
      background: url(../assets/img/green-arr.png) 0 0 no-repeat;
      height: 25pt;
      width: 24pt;
      display: inline-block;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  } */

.constant-arrow {
	background: url(../assets/img/constant.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.red-up {
	background: url(../assets/img/arrow/red-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.red-down {
	background: url(../assets/img/arrow/red-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.orange-up {
	background: url(../assets/img/arrow/orange-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.orange-down {
	background: url(../assets/img/arrow/orange-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.blue-up {
	background: url(../assets/img/arrow/blue-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.blue-down {
	background: url(../assets/img/arrow/blue-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.black-up {
	background: url(../assets/img/arrow/black-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.black-down {
	background: url(../assets/img/arrow/black-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.green-up {
	background: url(../assets/img/arrow/green-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.green-down {
	background: url(../assets/img/arrow/green-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.teal-up {
	background: url(../assets/img/arrow/teal-up.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.teal-down {
	background: url(../assets/img/arrow/teal-down.png) 0 0 no-repeat;
	height: 19pt;
	width: 14pt;
	display: inline-block;
	cursor: pointer;
	position: absolute;
	left: -10px;
	top: 3px;
	background-size: contain;
}

.tick-bx {
	position: relative;
	top: 3px;
	width: 19px;
	height: 17px;
}

.prod-count {
	position: relative;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	color: white;
	display: inline-block;
	line-height: 27px;
	text-align: center;
	font-size: 12px;
	height: 28px;
	width: 28px;
	font-weight: 500;
}

.dot-red {
	background-color: red;
}

.dot-shim {
	height: 21px;
	width: 21px;
	border-radius: 50%;
	display: inline-block;
}

.square {
	height: 15px;
	width: 80px;
	display: inline-block;
}

.highlight-tab {
	border-bottom: 2px solid var(--primary-color) !important;
	color: var(--primary-color);
	font-weight: 500;
}

.circle {
	width: 33px;
	height: 33px;
	padding-top: 4px;
	line-height: 500px;
	border-radius: 50%;
	font-size: 50px;
	color: #fff;
	text-align: center;
}

.c-red {
	background-color: #ff2d55;
}

.c-orange {
	background-color: #ff9500;
}

.c-yellow {
	background-color: #86c06a;
}

.circle div {
	font-size: 18px;
	height: 100%;
	line-height: 1.25;
	padding: 0;
	text-align: center;
}

.bar {
	position: relative;
	float: left;
	min-width: 1%;
	height: 100%;
	background: var(--success-color);
	transition: width 3s ease;
}

.progress {
	padding: 0;
	width: 100%;
	border: 1px solid #e3dede;
	overflow: hidden;
	background: #e3dede;
}

.sub-dot {
	background: url(../assets/img/off.svg) 0 0 no-repeat;
	background-size: cover !important;
	height: 12pt;
	width: 12pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
}

.sub-open {
	height: 14pt;
	width: 14pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
	background-size: 18px;
}

.sub-inprogress {
	background: url(../assets/img/check3.png) 0 0 no-repeat;
	background-size: cover !important;
	height: 12pt;
	width: 12pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
}

.drag-handle {
	background: url(../assets/img/handle2.png) 0 0 no-repeat;
	background-size: cover !important;
	height: 13pt;
	width: 15pt;
	cursor: pointer;
	display: inline-block;
	vertical-align: bottom;
}

.add-btn {
	bottom: 8px;
	position: relative;
	left: 6px;
	color: grey;
}

.disable {
	pointer-events: none;
}

.example-custom-placeholder {
	background-color: beige;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

	/* width: 100%; */
	font-weight: 500;
	padding: 4px;
	border-radius: 9px;
}

/**Left Sidebar width changes*/
@media (min-width: 992px) {
	/* .scroll-sidebar {
        width: 278px;
    } */

	.filter-fixed {
		width: 278px;
	}
}

@media (min-width: 1400px) {
	.scroll-sidebar,
	.filter-fixed {
		width: 323px;
	}
}

@media (min-width: 1920px) {
	.scroll-sidebar,
	.filter-fixed {
		width: 323px;
	}
}

/****----------------***/

.dropdown-item {
	color: var(--color-dark);
	transition: all 0.2s ease-in;

	&:hover {
		color: var(--color-dark);
	}
}

.dd-highlight-item {
	background-color: #e7e6e6;
	color: #ea4b62 !important;
}

.sr-only {
	display: none;
}

.close-upload {
	color: var(--primary-color);
	background-color: #fff;
	padding: 3px 5px 5px 8px;
	font-size: 16px;
	border-radius: 100px;
	top: 4px;
	position: absolute;
	cursor: pointer;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
	width: 25px;
	height: 25px;
	right: 2px;
}

/*****Section*****/

.section {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	border-radius: 9px;
}

.shadow {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	border-radius: 10px;

	.row .pb-icon .pb-close_circle {
		height: 20px;
		width: 20px;
	}
}

.edit-cell {
	background: #ffcdcd;
	border: 1px solid #ffcdcd;
	border-radius: 5px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.close-x {
	color: var(--primary-color);
	background-color: white;
	padding: 3px 5px 5px 8px;
	font-size: 16px;
	border-radius: 26px;
	top: -26px;
	position: absolute;
	left: 51%;
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 4px 0px rgba(77, 54, 54, 0.75);
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
	border-radius: 100px;
	width: 25px;
	height: 25px;
}

.example-box {
	padding: 20px 10px;
	border-bottom: solid 1px #ccc;
	color: rgba(0, 0, 0, 0.87);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	cursor: move;
	background: white;
	font-size: 14px;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
	border: none;
}

/**admin panel */

.pen {
	background: url(../assets/img/pen.png) 0 0 no-repeat;
	display: inline-block;
	cursor: pointer;
	background-size: 29px;
}

.insight {
	background: url(../assets/img/insight.png) 0 0 no-repeat;
	display: inline-block;
	cursor: pointer;
	background-size: 29px;
}

/**subtask cell assigneduser**/

.assignee-img {
	background-color: rgb(234 75 98);
	width: 28px;
	height: 28px;
	float: right;
	border-radius: 25px;
	text-align: center;
	padding: 4px;
	color: white !important;
	background-size: cover;
	text-transform: uppercase;
	font-weight: 500;
}

.f-17 {
	font-size: 17px;
}

/**Ngx Material TimePicker*/

.timepicker__header {
	background-color: #ea4b62 !important;
}

.clock-face__number > span.active {
	background-color: #ea4b62 !important;
	color: #ffffff !important;
}

.timepicker-overlay,
.timepicker-backdrop-overlay {
	z-index: 10000000 !important;
}

.timepicker-button {
	color: #ea4b62 !important;
}

.search-form {
	border: 1px solid #ced4da;
	border-radius: 7px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	background-color: #ffffff;
}

/**Post author online/offline*/

.author-offline {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--border-color-dark) !important;
	border: 2px solid var(--border-color-deep);
	position: absolute;
	left: 36px;
}

.author-online {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: var(--success-color) !important;
	border: 2px solid #fff;
	position: absolute;
	left: 36px;
}

.p-r {
	position: relative;
	bottom: 2px;
}

.last {
	left: 26px;
	width: 13px;
	height: 13px;
}

/**comment view count*/

.view {
	position: relative;
	top: 2px;
}

.comment-view {
	background: url(../assets/img/eyeMagenta.svg) 0 0 no-repeat;
	width: 21px;
	height: 20px;
	cursor: pointer;
	display: inline-block;
	background-size: cover !important;
	vertical-align: bottom;
}

/**Left sidebar*/

.sidebar-link {
	display: flex;
	align-items: center;

	.right-icon {
		display: inline-flex;
	}

	&[aria-expanded='true'] .right-icon {
		transform: rotate(180deg);
	}
}

@media (min-width: 992px) {
	body {
		overflow: auto !important;
	}

	main {
		margin-left: var(--offcanvas-width);
	}

	.offcanvas-backdrop::before {
		display: none;
	}

	.sidebar-nav {
		-webkit-transform: none;
		transform: none;
		visibility: visible !important;
		height: calc(100% - var(--topNavbarHeight));
		top: var(--topNavbarHeight);
	}
}

/**kanben View**/

.card-br {
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
	border-radius: 18px;
}

.card-sc {
	height: 600px;
	overflow-y: auto;
}

.kanban-bg {
	margin-right: 1rem !important;
	background: #f9f9f9;
	border-radius: 9px;
	font-size: 14px;
}

.kanban-user {
	width: 29px;
	height: 29px;
	background-size: cover;
	border-radius: 100px;
}

.kanban {
	.file-attachment {
		a {
			font-size: 14px;
		}

		.pb-icon {
			width: 35px !important;
			height: 42px !important;
		}
	}

	agm-map.sebm-google-map-container {
		width: 100%;
		height: 160px;
		display: block;
	}

	.attachment {
		min-height: 200px;

		.single-image {
			height: 160px;
			border: 1px solid var(--border-color-light);
		}
	}
}

.more {
	background: url(../assets/img/more.png) 0 0 no-repeat;
	height: 4pt;
	display: inline-block;
	cursor: pointer;
}

.cdk-overlay-container {
	z-index: 1000000 !important;
	// backdrop-filter: blur(2px);
	background-color: #00000005;
}

.disable {
	pointer-events: none;
}

.pre-line {
	white-space: pre-line;
}

.createBtn {
	text-align: center;
	height: 40px;
	width: 40px;
	font-size: 1.625rem;
	color: white;
	font-weight: 500;
	background-color: var(--primary-color);
	border-radius: 20px;
	cursor: pointer;
	z-index: 1051;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.kanban-cp {
	padding: 9px;
	background: #1ecd7d;
	color: white;
	font-weight: 500;
	border-radius: 13px;
}

.kanban-search {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	border-radius: 18px;

	.input-group-text {
		border: none;
		background: #ffffff;
	}

	.form-control {
		background: #ffffff;
	}
}

.state-br {
	background: red;
	padding: 2px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
	margin-left: 11px;
	margin-bottom: 5px;
	width: 262px;
}

.circle-board {
	width: 29px;
	height: 29px;
	padding-top: 4px;
	line-height: 500px;
	border-radius: 50%;
	font-size: 50px;
	color: #fff;
	text-align: center;

	div {
		font-size: 16px;
		height: 100%;
		line-height: 1.25;
		padding: 0;
		text-align: center;
	}
}

/**Calender Popup*/

.cal-month-view .cal-cell-top {
	min-height: 58px !important;
	flex: 1;
}

.due-cal .cal-month-view .cal-cell-top {
	min-height: 45px !important;
	flex: 1;
}

.start-time {
	background: url(../assets/img/start_time.png) 0 0 no-repeat;
	width: 22px;
	height: 22px;
}

.end-time {
	background: url(../assets/img/end_time.png) 0 0 no-repeat;
	width: 22px;
	height: 22px;
}

.repeat {
	background: url(../assets/img/repeat_icon.png) 0 0 no-repeat;
	width: 22px;
	height: 22px;
}

.reminder {
	background: url(../assets/img/reminder_icon.png) 0 0 no-repeat;
	width: 22px;
	height: 22px;
}

.pb-due {
	position: relative;
	top: 0;
	left: 0;
}

.dotted {
	position: relative;
	top: 0;
	left: 0;
	height: 35px;
}

.rect {
	position: absolute;
	top: 7px;
	left: 9px;
	height: 19px;
}

.cherry {
	color: var(--color-primary-light);
	font-family: 'Inter';
}

.sheet {
	font-size: 14px;
	border-radius: 8px;
	border: 1px solid white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/**Todo actions sheet*/

.dropend .btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none;
}

.sheet .dropdown-item {
	cursor: pointer;
	color: #474747;
}

.tf {
	transform: translate(48px, 0px) !important;
}

.ts {
	transform: translate(25px, 0px) !important;
}

.dropdown-item:hover {
	background: none !important;
}

/**Styling of showing count,actual time and estimated time**/

.header-second-sec-container {
	display: inline-flex;
	width: 100%;

	span {
		height: 2rem;
		position: relative;
		top: -3px;
		left: 3px;
	}
}

/* New todo list post ui*/

.new-todo-post {
	width: auto;
	padding-bottom: 20px;
	border-radius: 15pt;
	overflow: hidden;
	margin-bottom: 0.5rem;
	border: 1px dashed var(--primary-color);
	transition: all 0.3s ease-in-out;

	&:hover {
		box-shadow: rgb(0 0 0 / 15%) 0px 3px 8px;
	}

	.primary-stroke {
		margin-right: 5px;
		margin-top: 7px;
		height: 25px;
	}
}

.tasks-todo-new {
	height: 20px;
	background-size: contain !important;
	margin-top: 3px;
	margin-right: 5px;
}

.todo-texts-new {
	padding-left: 0px;
	padding-top: 5px;
	font-weight: 500;
	position: relative;
	top: 3px;
}

.color-sky-blue {
	color: var(--success-color);
}

.todo-post-options {
	min-width: 50px;
	position: relative;
	top: 5px;
}

.progress-bar {
	padding: 0;
	width: 100%;
	border: 1px solid #e3dede;
	overflow: hidden;
	background: #e3dede;
	border-radius: 10px;

	.bar {
		position: relative;
		float: left;
		min-width: 1%;
		background-color: var(--success-color);
		height: 100%;
		-webkit-transition: width 3s ease;
		-moz-transition: width 3s ease;
		transition: width 3s ease;
	}
}

.completed-task-bar {
	height: 12px;
	padding-right: 100px;
	padding-left: 100px;
}

/* End */

/* NEW ACTIVIY LOG AND USER PRIFILE UI CSS */

.container-demo {
	width: 100%;
	height: auto;
}

.profpagbc {
	width: 100%;
	height: auto;

	h2 {
		width: 100%;
		height: auto;
		background-color: #f0f1f3;
		color: var(--primary-700);
		font-size: 18px;
		font-weight: 500;
		padding: 15px;
	}
}

.tarcktabgc {
	width: 100%;
	margin-bottom: 20px;

	table {
		width: 100%;

		td {
			font-size: 16px;
			color: #696969;
			text-align: left;
			vertical-align: top;
			padding: 10px;

			i {
				margin-right: 10px;
			}

			&:first-child {
				width: 120px;
			}

			&:last-child {
				color: var(--primary-700);
				font-weight: 500;
			}
		}
	}
}

.actbtn {
	border-radius: 100px;
	border: 1px solid var(--primary-color) !important;
	color: #ffffff !important;
	background-color: var(--primary-color) !important;
	font-size: 16px;
	font-weight: 500;
	padding: 15px 10px;
	transition: all 0.5s;
	display: inline-block;
	text-decoration: none;
	margin: 15px 5px;
	width: 50%;
	text-align: center;

	&:hover,
	&.active {
		border-color: #a0a0a0;
		color: #282828;
		background-color: #ffffff;
	}
}

.postbtn {
	border-radius: 100px;
	border: 1px solid #a0a0a0;
	color: #282828;
	background-color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	padding: 15px 10px;
	transition: all 0.5s;
	display: inline-block;
	text-decoration: none;
	margin: 15px 5px;
	width: 50%;
	text-align: center;

	&:hover {
		border-color: var(--primary-color);
		color: #ffffff;
		background-color: var(--primary-color);
	}
}

.actbtn.active {
	border-color: var(--primary-color);
	color: #ffffff;
	background-color: var(--primary-color);
}

.btncovtap {
	width: 100%;
	text-align: center;
	display: flex;
}

.dotbord {
	width: 100%;
	height: 1px;
	background-color: #d7d7d7;
	margin-bottom: 5px;
}

.proficcop {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	display: inline-block;
	margin-right: 12px;
	position: relative;

	img {
		width: 100%;
		height: auto;
		border-radius: 100%;
	}

	span {
		position: absolute;
		top: 55px;
		right: 0;
		width: 14px;
		height: 14px;
		background-color: #03ff00;
		border-radius: 100%;
		display: inline-block;
		z-index: 10;
	}
}

.pdsedbhead {
	display: inline-block;
	width: calc(100% - 100px);

	h3 {
		color: #000000;
		font-size: 18px;
		font-weight: 500;
		margin-top: 20px;
		margin-bottom: 8px;
		width: 100%;
		display: inline-block;
	}

	h4 {
		color: #9f9f9f;
		font-size: 16px;
		font-weight: normal;
		margin-top: 0;
		width: 100%;
		display: inline-block;
	}
}

.traprmcrack {
	width: 100%;
	height: 58vh;
	list-style: none;
	margin: 0;
	padding: 10px;
	overflow-y: scroll;
	overflow-x: hidden;

	li {
		width: 100%;
		height: auto;
		border-bottom: 1px solid #d7d7d7;
		color: #000000;
		padding: 10px 100px 2px 47px;
		position: relative;
		min-height: 50px;
		margin-top: 5px;

		h6 {
			color: #282828;
			font-size: 12px;
			font-weight: normal;
			position: absolute;
			top: 15px;
			right: 0;
			margin: 0;
		}

		h3 {
			color: #000000;
			font-size: 18px;
			font-weight: 500;
			margin: 1px 0 2px;
		}
	}
}

.tapbdtj {
	font-size: 35px;
	position: absolute;
	top: 14px;
	left: 0;
}

.radbackl {
	position: absolute;
	top: 11px;
	left: 0;
}

.traprmcrack li h4 {
	color: #6c6c6c;
	font-size: 14px;
	font-weight: normal;
	margin: 5px 20px 15px 0;
	display: inline-block;
}

.tskgtb {
	color: #ea005f;
	margin-right: 3px;
}

.customer-board-view-modal {
	agm-map.sebm-google-map-container {
		height: 165px;
	}

	.map-box {
		height: unset;
		margin-top: 2px;
	}
}

.task_post_btn {
	margin-bottom: 18px;
	background-color: var(--primary-color) !important;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: var(--primary-color-light) !important;
	}
}

.add_task_new_btn .btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	gap: 8px;
	width: auto;
	height: 38px;
	background: rgba(255, 255, 255, 0.000001);
	border: 1px solid var(--primary-color);
	border-radius: 4px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: var(--primary-color);
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-top: 7px;
}

.done_task_new_btn .btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	gap: 8px;
	width: auto;
	height: 38px;
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
	border-radius: 4px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: #ffffff;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-top: 7px;
}

.task_proj_info {
	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: var(--primary-color);
	}

	font-size: 14px;
	margin-top: 2px;
}

.post-textarea {
	border: 1px solid var(--border-color-light) !important;
	margin: 20px 0px;
}

.btn-create-bar {
	background-color: var(--primary-color);
	border-radius: 36px;

	.row {
		color: white;
		font-size: 20px;
		height: 30px;
		font-weight: 500;
		font-family: 'Inter';
	}

	.plus {
		text-align: center;
		height: 34px;
		width: 34px;
		font-size: 30px;
		color: var(--primary-color);
		font-weight: 500;
		background-color: #ffffff;
		border-radius: 42px;
		cursor: pointer;
		z-index: 1051;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
		line-height: 30px;
	}
}

.form-control {
	background-color: var(--input-backgorund);
	color: var(--color-dark);

	&:focus,
	&:active:focus {
		outline: none !important;
		outline-offset: -2px;
		box-shadow: none !important;
	}
}

.attachment-icons .btn-attach {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	padding: 6px;

	path {
		stroke: var(--color-primary-active);
	}

	&:hover {
		border-radius: 50%;
		background-color: rgb(241, 241, 242);
	}
}

.post-list-right-sidebar {
	position: fixed;
	display: flex;
	flex-direction: column;
	width: 21%;
	overflow: auto;
	height: 650px;
}

.start_day_text_cont svg {
	path {
		stroke: var(--primary-color);
	}

	margin-top: 5px;
	margin-right: 10px;
}

.group-con-icon {
	background-color: white !important;
	border: 1px solid var(--border-color-dark);
	padding: 2px 5px !important;

	svg path {
		stroke: var(--primary-color);
	}
}

.grp-outer {
	padding: 8px 8px !important;
}

.primary-color {
	color: var(--primary-color);
}

.success-color {
	color: var(--success-color);
}

.primary-stroke path {
	stroke: var(--primary-color);
}

.muted-icon path {
	stroke: var(--tw-color-gray-600);
}

.btn-primary {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	transition: all 0.2s ease-in-out;

	&:focus,
	&:active:focus,
	&:active:hover,
	&:hover,
	&:active {
		background-color: var(--primary-color-light);
		border-color: var(--primary-color-light);
	}

	svg {
		* {
			fill: var(--primary-button-color) !important;
		}
	}
}

.btn-secondary {
	background-color: #fff4f8;
	border-color: transparent;
	transition: all 0.2s ease-in-out;
	color: var(--primary-color);

	&:hover,
	&:active {
		background-color: var(--tw-color-gray-50);
		border-color: var(--primary-color);
		color: var(--primary-color);
	}
}

.listing-icons svg {
	height: 18px;
}

.cal-month-view {
	.cal-cell-top {
		cursor: pointer !important;
	}

	.cal-cell-row .cal-cell:hover,
	.cal-cell.cal-has-events.cal-open {
		cursor: default !important;
	}
}

.card {
	.card-header {
		.title {
			color: var(--color-dark);
		}
	}

	&.re-card {
		border: 1px solid var(--border-color-light) !important;
		border-radius: 5px;
		background: var(--primary-bg-color);
		border-radius: 5px;
		height: 100%;
		padding: 15px 5px;

		&.full-width {
			padding-right: 0px;
			padding-left: 0px;
		}

		.card-header {
			background-color: transparent;
			border: none;

			.title {
				color: var(--tw-color-gray-800);
				padding-left: 5px;
			}
		}

		.card-body {
			padding: 0px;
		}
	}
}

.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 14px;
	z-index: 100000 !important;

	.list-item {
		box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
	}
}

app-list-task-item.cdk-drag.cdk-drag-preview {
	box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 15%);
	border-radius: 8px;
	border: 1px solid #f2b7cb;
}

.cdk-drag.todo-item.cdk-drag-preview,
.cdk-drag.cdk-drag-preview .todo-item {
	background-color: #ffecf7bd;
	height: 100%;
	display: flex;
	position: relative;
	padding-top: 10px;
	padding-left: 10px;
	margin: 0px;
	border-radius: 8px;
}

app-projects .mat-tab-list .mat-tab-labels .mat-tab-label {
	padding: unset !important;
}

app-project .my-tasks .mat-tab-list .mat-tab-labels .mat-tab-label {
	min-width: 125px !important;
	padding: unset !important;
}

.border-bottom {
	border-color: var(--border-color-light) !important;
}

.pagination-text {
	font-size: 13px;
	color: var(--color-light);
}

.notification-overlay-backdrop,
.drawer-overlay-backdrop,
.filter-overlay-backdrop,
.members-overlay-backdrop {
	background-color: transparent;
}

.error-modal-backdrop {
	fill: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
}

// .drawer-container-class {
// 	// margin-top: 13.75rem;
// }

/*
* Primary Color Classes
*/
.text-primary {
	color: var(--primary-color) !important;
}

/** Fallback Section **/
.fallback-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.fallback {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 3rem;

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1.5rem;

			&.margin-top-10 {
				margin-top: 10%;
			}

			&.margin-top-18 {
				margin-top: 18%;
			}

			.text-section {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 0.75rem;
				text-align: center;
				text-wrap: balance;

				.title-text {
					color: rgba(0, 0, 0, 0.8);
					font-size: 1.25rem;
					font-weight: 500;
					line-height: 100%;
				}

				.additional-info {
					color: var(--gray-500, #667085);
					font-size: 1rem;
					font-weight: 400;
					line-height: 120%;
					max-width: 30rem;
				}
			}

			.create-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				padding: 0.625rem 1.125rem;
				border-radius: 0.5rem;
				border: 1px solid var(--primary-color);
				background: var(--primary-color);
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

				color: var(--white, #fff);
				font-size: 1rem;
				font-weight: 500;
				line-height: 1.5rem;
				white-space: nowrap;

				transition: all 0.3s linear;

				&:hover {
					scale: 1.025;
					background-color: var(--primary-600);
				}

				&:focus {
					background-color: var(--primary-600);
					border: 1px solid var(--primary-600);
					box-shadow: 0px 0px 0px 4px #f0f8ff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				}
			}
		}
	}
}

// Dynamic Logo
.logo img.dynamic-logo {
	max-width: 8rem;
	max-height: 8rem;
}

/** INPUT BASE STYLES **/
.input-container-base-style {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 0.625rem 0.875rem;
	border-radius: 0.5rem;
	border: 1px solid var(--gray-300);
	background: #fff;
	transition: all 0.5s ease-out;

	&:focus-within {
		border: 1px solid var(--primary-300);
		box-shadow: 0px 0px 0px 4px #e0f0fe, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}

	input {
		border: none;
		background: transparent;
		flex: 1;
		background: #fff;
	}

	&.error-input {
		border: 1px solid var(--red-300);

		&:focus-within {
			border: 1px solid var(--red-300);
			box-shadow: 0px 0px 0px 4px var(--red-100), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}
	}

	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px var(--primary-bg-color) inset !important;
		-webkit-text-fill-color: #000000 !important;
	}
}
