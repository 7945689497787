// Overwrite Angular Material Tabs
// @see: https://material.angular.io/components/tabs/overview

/** Material Tab Style Overwrites */

.mat-tab-list {
    .mat-tab-labels {
        border-bottom: 1px solid var(--border-color-light);

        .mat-tab-label {
            opacity: 1 !important;
            padding: 0 5px !important;
            min-width: 70px;
        }
    }

    .mat-ink-bar {
        min-width: 70px;
    }

    .mat-tab-labels .mat-tab-label {
        .mat-tab-label-content {
            color: var(--tw-color-gray-600) !important;
        }

        &.mat-tab-label-active .mat-tab-label-content {
            color: var(--tw-color-gray-900) !important;
        }

        &.mat-tab-disabled{
            .mat-tab-label-content {
                color: var(--tw-color-gray-400) !important;
            }
        }
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-color) !important;
}

.mat-tab-body-content {
    min-height: 700px !important;
    overflow-x: hidden !important;
}

.dummy-tabs {
    &.hide {
        opacity: 0 !important;
    }

    .mat-tab-body-content,
    .mat-tab-body {
        min-height: 0px !important;
        height: 0px !important;
    }
}

.h-full-tabs .mat-tab-body-content {
    height: 100%;
    max-height: 100% !important;
    overflow: auto;
}
