// Overwrite Angular Calendar Styles
// @see: https://mattlewis92.github.io/angular-calendar/#/kitchen-sink

.calendar-list-container .calendar-view .cal-month-view {
	.cal-day-cell {
		&.cal-today .cal-day-number {
			background-color: var(--primary-color);
		}

		+ .cal-today {
			background-color: white;
		}
	}

	.cal-day-number {
		font-size: 1.2em;
		font-weight: 400;
		opacity: 0.5;
		margin-top: 15px;
		margin-right: 20px;
		float: right;
		margin-bottom: 10px;
		white-space: nowrap;
	}

	.cal-day-cell {
		height: 30px !important;
		width: 59px !important;
		font-size: 14px;
		padding: 7px 5px;
		border-radius: 5px;
		margin: 0px !important;
		min-width: 60px !important;
		min-height: 60px !important;
		position: relative !important;
		text-align: center;
		transition: all 0.15 s ease-in-out;

		&.cal-today {
			background-color: transparent;

			.cal-day-number {
				font-size: 17px;
				user-select: none;
				background-color: var(--primary-color);
				box-shadow: 0px 0px 30px 0px var(--primary-100);
				color: var(--tw-color-white) !important;
				padding: 8px 10px;
				padding-top: 15px;
				margin: 5px 5px;
				border-radius: 5px;
				opacity: 1;
				color: #e1e1e1;
				line-height: 15px;
				width: 42px;
				height: 42px;
			}
		}
	}

	.cal-cell-row {
		&:hover {
			background-color: transparent;
		}

		.cal-day-cell:hover {
			background-color: var(--tw-color-gray-50);
		}
	}

	.cal-header .cal-cell:hover {
		background-color: var(--tw-color-gray-50);
	}

	.cal-is-day-selected .cal-day-number {
		background-color: var(--primary-200);
		color: var(--primary-color) !important;
		box-shadow: 0px 0px 30px 0px var(--primary-100);
		padding: 8px 10px;
		padding-top: 15px;
		margin: 5px 5px;
		border-radius: 5px;
		opacity: 1;
		line-height: 15px;
		width: 42px;
		height: 42px;
	}

	.cal-day-badge {
		background-color: var(--primary-100);
		color: var(--primary-color);
		padding: 3px 6px;
		font-size: 10px;
		transition: all 0.3s ease-out;
		position: absolute !important;
		top: 0.25rem;
		right: 0.25rem;
		margin: auto;
	}

	.cal-day-cell.cal-today .cal-day-badge {
		box-shadow: 0px 0px 4px rgba(135, 135, 135, 0.25);
	}

	// .cal-cell-row .cal-day-cell:hover .cal-day-badge {
	// 	/* border: 1px dashed var(--primary-color); */
	// }

	.cal-events {
		display: none;
	}
}
