.dropdown {
    .dropdown-menu {
        border: none;
        padding: 0px;
        background-color: var(--primary-bg-color);
    }

    &.open {
        background-image: none;
    }

    &.show {
        display: block;
        padding: 0px;
        border: none;
    }

    &.ngx-contextmenu {
        padding: 0px;
        box-shadow: none;
    }
}

.ngx-contextmenu {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: var(--border-color-dark);
    border-style: solid;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);

    min-width: 160px;

    li {
        display: block;
        color: var(--color-dark);
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        border: 1px solid transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        // border-radius: 5px;

        min-width: 130px;

        &:first-child {
            border-top-left-radius: 0.375rem;
            border-top-right-radius: 0.375rem;
        }

        &:last-child {
            border-bottom-right-radius: 0.375rem;
            border-bottom-left-radius: 0.375rem;
        }

        &.divider {
            padding: 0 10px;
            border-top-width: 0px;
            border-color: var(--border-color-light);
        }

        &:hover {
            background-color: var(--border-color-light);

            &:active {
                background-color: var(--border-color-light);
            }
        }

        span,
        div {
            color: var(--color-dark);
        }

        a {
            display: block;
            padding: 8px 13px;
        }

        svg {
            margin-right: 5px;
        }

        .hasSubMenu::before {
            font-size: 8px;
        }

        .delete {
            color: var(--danger-color);

            svg {
                path {
                    stroke: #EF4444;
                }
            }
        }
    }
}

.cdk-overlay-pane {
    &.ngx-contextmenu {
        padding: 0px;
    }
}