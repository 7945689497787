@import '/node_modules/bootstrap/scss/bootstrap.scss';

@import 'variables.scss';
@import './reset.scss';
@import './legacy-styles.scss';
@import './overwrite/overwrite.scss';
@import './dark-mode.scss';
@import './overwrite/material/cdk.scss';

@import './layouts/auth.scss';
@import './layouts/home.scss';

// bootstrap
@import './overwrite/bs/modal/modal.scss';

// third party
@import '@angular/cdk/overlay-prebuilt.css';

// tui editor and plugins
// @import '@toast-ui/editor/dist/toastui-editor.css';
// @import '@toast-ui/editor/dist/toastui-editor-viewer.css';
// @import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
@import 'prismjs/themes/prism.css';
@import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
@import './overwrite/tui/index.scss';

// antdesign
@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import './overwrite/antdesign/form-inputs.scss';
@import './overwrite/antdesign/button.scss';
@import './overwrite/antdesign/input.scss';
@import './overwrite/antdesign/select.scss';
@import './overwrite/antdesign/modal.scss';
@import './overwrite/antdesign/form.scss';
@import './overwrite/antdesign/date-picker.scss';

// reactflow
@import '@reactflow/core/dist/style.css';
@import '@reactflow/controls/dist/style.css';
@import '@reactflow/minimap/dist/style.css';
@import './overwrite/reactflow/custom-nodes.scss';

//import mat-checkbox styles
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '/node_modules/@angular/material/_index.scss';

// components
@import './components/badge.scss';
@import './components/fancy-checkbox-radio.scss';
@import './components/icon-button.scss';
@import './components/custom-flow.scss';
@import '../../projects/bee-ui/src/styles.scss';

.modal-vertical-centered {
	padding-top: 15%;
}

.option-list {
	.mat-radio-container,
	.mat-radio-outer-circle,
	.mat-radio-inner-circle {
		height: 15px;
		width: 15px;
	}

	.mat-radio-button .mat-radio-ripple {
		left: calc(50% - 12px);
		top: calc(50% - 13px);
		height: 25px;
		width: 25px;
	}
}

/* Override default variable */
// $skeleton-element-color: #ffd9f0;

/* Then import scss source file */
@import '~skeleton-screen-css/dist/index.scss';

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
	border-color: var(--primary-color);
	// box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--primary-color);
	box-shadow: none;
	outline: 0 none;

	&::selection {
		color: var(--primary-button-color);
		background-color: var(--primary-color);
	}
}

.layout-wrapper {
	display: flex;
	flex-direction: row;

	.sidebar-container,
	.sidebar-nav {
		width: 300px;
	}

	.main-content {
		width: 75%;
		margin-left: 15px;
	}
}

@media (max-width: 1400px) {
	.layout-wrapper {
		.sidebar-container,
		.sidebar-nav {
			width: 270px !important;
		}

		.main-content {
			width: 72%;
			margin-left: 15px;
		}
	}
}

@media (max-width: 1300px) {
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 100% !important;
	}
}

@media (max-width: 1200px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm {
		max-width: 1200px;
	}
}

.attachment-overlay-backdrop {
	background-color: #00000060;
	backdrop-filter: blur(2px);
}

agm-map {
	height: 300px !important;
	width: 100%; /* This is really important*/
}


.ant-select, .ant-select-selector {
    border-radius: 8px !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
    /* padding: 10px 14px !important; */
    height: 46px;
    /* border-color: #D0D5DD; */
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
    height: 46px;
    border-color: #D0D5DD;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 17px;
	flex: 1;
    color: var(--gray-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: capitalize;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
    font-size: medium;
    padding-right: .5rem;
}