.ant-btn {
	display: inline-flex;
	align-items: center;
	border-radius: 5px;

	span {
		display: flex;
	}

	&.ant-btn-primary {
		background-color: var(--primary-color);
		border-color: var(--primary-color);
		color: white;

		&:hover {
			background-color: var(--primary-color-light);
			color: white;
		}
	}
}
