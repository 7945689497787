* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	font-family: 'Inter', sans-serif;
}

html,
body * {
	font-family: 'Inter', sans-serif;
}

html {
	left: 0px !important;
	top: 0px !important;
	background-color: transparent;
}

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/metropolis.light.otf') format('truetype');
}

body {
	font-family: 'Inter' !important;
	background: rgb(247, 247, 247);
}

::placeholder {
	color: #afacac;
	font-size: 1em;
	font-family: 'Inter Light';
}

a {
	text-decoration: none;
	color: #000000;

	&:hover {
		text-decoration: none;
		color: #353434;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter';
}

p {
	font-size: 14px;
}

.link {
	font-size: 16px;
}

p a {
	color: rgb(1, 224, 171) !important;

	&:hover {
		color: rgb(6, 147, 113) !important;
	}
}

ul li {
	list-style: none;
}

nav {
	padding: 0px !important;
}

input,
textarea,
select {
	font-family: 'Inter', sans-serif !important;

	&::placeholder {
		font-family: 'Inter', sans-serif;
	}
}

[contenteditable]:focus {
	outline: 1px solid var(--primary-color);
	border-radius: 5px;
}

// remove button focus outline
.btn {
	outline: none !important;
	outline-offset: -2px;
	box-shadow: none !important;

	&:focus,
	&:active:focus,
	&:active:hover,
	&.focus,
	&.active:focus,
	&.active.focus {
		outline: none !important;
		outline-offset: -2px;
		box-shadow: none !important;
	}
}

.text-muted {
	color: var(--border-color-deep) !important;
}

.btn-social-login {
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid #ccc;
	text-align: center;
	border-radius: 10px;
	margin: 15px 0px;
	font-size: 20px;
	font-weight: bold;
	padding: 10px;

	svg {
		margin-right: 5px;
	}
}
