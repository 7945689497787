.ant-input,
.ant-input-number {
	border-radius: 5px !important;

	&:hover {
		border-color: var(--color-primary-light);
	}

	&:focus,
	.ant-input-focused {
		box-shadow: none !important;
		border-color: var(--color-primary-hover);
		border-right-width: 1px !important;
		outline: 0;
	}
}
