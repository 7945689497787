.custom-flow {
	.flowchart-links-layer {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	#workFlowBoard {
		width: 100%;
		min-height: 70vh;
		--dot-bg: var(--gray-50);
		--dot-color: #333;
		--dot-size: 1px;
		--dot-space: 22px;
		background: linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
			linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space), var(--dot-color);
	}

	.flowchart-link path {
		stroke-width: 2px; /* Adjust the value to your desired stroke width */
		stroke: #c2ccd8;
	}

	.flowchart-example-container {
		height: 500px;
		border: 1px solid #bbb;
		margin-bottom: 10px;
	}

	.custom-handle {
		position: absolute;
		right: 10px;
		top: 40px;
		color: #333333;
		display: none;
		font-size: 21px;
		border: 0 none;
		background: none;
		z-index: 5;
	}

	.hover {
		box-shadow: -1px 2px 9px 2px #d2daf8;
	}

	.flowchart-operator-title i.fa.fa-hist.tool-hist {
		margin-left: 5px;
	}
	.flowchart-operator-title i.fa.fa-box.tool-box {
		margin-left: 5px;
	}

	.highlight {
		box-shadow: -1px 2px 9px 2px #2fb53f !important;
	}

	.flowchart-operator-title i {
		font-size: 16px;
		vertical-align: middle;
		padding: 0px;
		margin: 0px;
	}

	.del-optr-btn {
		position: absolute;
		right: 0;
		top: -10px;
		display: block;
		width: auto;
		font-weight: 700;
	}

	// .flowchart-operator-connector-arrow {
	// 	top: 0px;
	// 	border-left: 10px solid rgb(211, 216, 226) !important;
	// }

	.flowchart-operator-connector-small-arrow {
		top: 0px;
		border-left-color: rgb(213, 223, 238) !important;
	}

	.flowchart-label {
		font-size: 12px;
		text-align: center;
		padding: 10px;
	}

	.flowchart-container {
		position: relative;
		overflow: hidden;
	}

	.flowchart-operators-layer {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}

	.flowchart-temporary-link-layer {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}

	.flowchart-operators-layer,
	.flowchart-temporary-link-layer {
		pointer-events: none;
	}

	.flowchart-temporary-link-layer {
		display: none;
	}

	.flowchart-link,
	.flowchart-operator {
		cursor: default;
	}

	.flowchart-operator-connector {
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.flowchart-operator-connector-label {
		font-size: small;
	}

	.flowchart-operator-inputs .flowchart-operator-connector-label {
		margin-left: 14px;
	}

	.flowchart-operator-outputs .flowchart-operator-connector-label {
		text-align: right;
		margin-right: 5px;
	}

	.flowchart-operator-connector-arrow {
		width: 8px;
		height: 10px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		position: absolute;
	}

	.flowchart-operator-connector-small-arrow {
		width: 0px;
		height: 0px;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 5px solid transparent; /*rgb(100, 100, 100);*/
		position: absolute;
		pointer-events: none;
	}

	.flowchart-operator-inputs .flowchart-operator-connector-arrow {
		left: -5px !important;
		// top: -10px !important;
		// visibility: hidden;	
	}

	.flowchart-operator-outputs .flowchart-operator-connector-arrow {
		right: -4px !important;
		// top: -4px !important;
		// visibility: hidden;
	}

	.flowchart-operator-inputs .flowchart-operator-connector-small-arrow {
		// left: -1px;
		visibility: hidden;

	}

	.flowchart-operator-outputs .flowchart-operator-connector-small-arrow {
		// right: -7px;
		// top: 3px;
		visibility: hidden;

	}

	.unselectable {
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;

		/*
			Introduced in IE 10.
			See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
		*/
		-ms-user-select: none;
		user-select: none;
	}

	/* Default Operator */

	.flowchart-operator {
		position: absolute;
		width: 135px;
		background: #fff;
		pointer-events: initial;
		min-height: 85px;
		border-radius: 10px;
		border: 1px solid #d8e2f0;
	}

	.flowchart-operator:hover {
		border-color: #54abe7 !important;
		box-shadow: 1px 0px 6px 1px #54abe7;
	}

	.flowchart-operator.selected {
		box-shadow: 1px 0px 6px 1px #54abe7;
		border: 1px 0px 10px 1px #54abe7;
	}

	.flowchart-operator .flowchart-operator-title {
		padding: 5px;
		box-sizing: border-box;
		height: auto;
		overflow: hidden;
		cursor: move;
		text-align: center;
		color: inherit;
		text-transform: capitalize;
		font-size: 15px;
		vertical-align: bottom;
		margin-top: 7px;
		font-weight: normal;
	}

	.flowchart-operator .flowchart-operator-inputs-outputs {
		display: table;
		width: 100%;
		margin-top: -13px;
	}

	.flowchart-operator .flowchart-operator-inputs,
	.flowchart-default-operator .flowchart-operator-outputs {
		display: table-cell;
		width: 50%;
	}

	.flowchart-operator:hover .custom-handle {
		display: block;
	}

	/***********************New********************/
	.flowchart-menu {
		display: none;
	}
	.dot-icon {
		display: none;
	}
	.hover .dot-icon {
		display: block;
	}
	.opid {
		visibility: hidden;
	}

	.dot-icon-visible{
		display: block !important;
	}

}
